import { Component } from '@angular/core';
import { GlobalService } from "src/app/services/global.service";

@Component({
  selector: 'learner-dashboard-panel',
  templateUrl: './learner-dashboard-panel.component.html',
})
export class LearnerDashboardPanelComponent {
  constructor(
    public globalService: GlobalService,
  ) {}
}
