import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../app/services/auth-guard.service";
import { NoAuthGuardService } from "./services/no-auth-guard.service";

const routes: Routes = [
  {
    path: "",
    loadComponent: () =>
      import("./pages/home/home.page").then((m) => m.HomePage),
    canActivate: [NoAuthGuardService],
  },
  {
    path: "contact",
    loadComponent: () =>
      import("./pages/contact/contact.page").then((m) => m.ContactPage),
    canActivate: [NoAuthGuardService],
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./pages/dashboard/dashboard-routing.module").then(
        (m) => m.routes,
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "forgot",
    loadChildren: () =>
      import("./pages/forgot/forgot.module").then((m) => m.ForgotPageModule),
  },
  {
    path: "about-us",
    loadChildren: () =>
      import("./pages/about-us/about-us.module").then(
        (m) => m.AboutUsPageModule,
      ),
  },
  {
    path: "student-bulk-upload",
    loadChildren: () =>
      import("./pages/student-bulk-upload/student-bulk-upload.module").then(
        (m) => m.StudentBulkUploadPageModule,
      ),
  },
  {
    path: "learner-dashboard",
    loadChildren: () =>
      import("./pages/learner-dashboard/learner-dashboard-routing.module").then(
        (m) => m.routes,
      ),
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
