import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared.module";
import { LoginComponent } from "./auth/login/login.component";
import { SignupComponent } from "./auth/signup/signup.component";
import { ButtonComponent } from "./button/button.component";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";
import { ComponentsRoutingModule } from "./components-routing.module";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { ModalComponent } from "./modal/modal.component";
import { NavComponent } from "./nav/nav.component";
import { ScrollTopComponent } from "./scroll-top/scroll-top.component";
import { SidePanelComponent } from "./side-panel/side-panel.component";
import { DashboardPanelComponent } from "./sidebar/dashboard-panel/dashboard-panel.component";
import { SettingsPanelComponent } from "./sidebar/settings-panel/settings-panel.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { StatusChipComponent } from "./status-chip/status-chip.component";
import { SvgIconComponent } from "./svg-icon/svg-icon.component";
import { LearnerDashboardPanelComponent } from './sidebar/learner-dashboard-panel/learner-dashboard-panel.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CardComponent } from './course-card/course-card.component';
import {BannerComponent} from "./banner/banner.component";
import { AntMenuComponent } from './ant-menu/ant-menu.component';
import { DropdownComponent } from './dropdown/dropdown.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsRoutingModule,
    RouterModule,
  ],
  declarations: [
    SvgIconComponent,
    SidebarComponent,
    HeaderComponent,
    ComingSoonComponent,
    ModalComponent,
    SidePanelComponent,
    DashboardPanelComponent,
    SettingsPanelComponent,
    NavComponent,
    ButtonComponent,
    NavComponent,
    LoginComponent,
    SignupComponent,
    FooterComponent,
    ScrollTopComponent,
    StatusChipComponent,
    LearnerDashboardPanelComponent,
    BreadcrumbComponent,
    CardComponent,
    BannerComponent,
    AntMenuComponent,
    DropdownComponent
  ],
  exports: [
    SvgIconComponent,
    SidebarComponent,
    HeaderComponent,
    ComingSoonComponent,
    ModalComponent,
    SidePanelComponent,
    DashboardPanelComponent,
    SettingsPanelComponent,
    NavComponent,
    ButtonComponent,
    NavComponent,
    LoginComponent,
    SignupComponent,
    FooterComponent,
    ScrollTopComponent,
    StatusChipComponent,
    LearnerDashboardPanelComponent,
    BreadcrumbComponent,
    CardComponent,
    BannerComponent,
    AntMenuComponent,
    DropdownComponent
  ],
})
export class ComponentsModule {}
