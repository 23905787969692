import { Location } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "src/app/services/global.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent {
  @Input() isDashboardPage = false;
  @Input() isSettingsPage = false;
  @Input() isLearnerDashboardPage = false;

  constructor(
    private location: Location,
    private router: Router,
    public globalService: GlobalService,
  ) {
    this.router.events.subscribe(
      () =>
        (this.isSettingsPage = this.location.path(false).includes("settings")),
    );
  }
}
