import { Component, Input } from "@angular/core";
import "firebase/compat/auth";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
})
export class FooterComponent {
  @Input() large = false;
}
