import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
})
export class ModalComponent implements OnInit {
  @ViewChild("dialog", { static: true }) dialog: ElementRef<HTMLDialogElement>;

  @Input() title: string;
  @Input() position: "center" | "side" = "center";
  @Input() showHeader = true;
  @Input() showLeftCornerTrashIcon: boolean = false;

  constructor() {}

  ngOnInit() {}

  public open(): Promise<void> {
    return new Promise((resolve) => {
      this.dialog.nativeElement.showModal();
      this.dialog.nativeElement.addEventListener("close", () => {
        resolve();
      });
    });
  }

  public close(): void {
    this.dialog.nativeElement.close();
  }
}
