import { Location } from "@angular/common";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { NavigationEnd, Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { TRANSLATION } from "@i18n";
import { HotToastService } from "@ngneat/hot-toast";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs/operators";
import { GlobalService } from "./services/global.service";
import { Profile } from "./shared/types";
import { UserData } from "./shared/user-data";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  username: string = this.translate.instant(TRANSLATION.LOADING);

  loggedIn = false;
  dark = false;
  isAdmin = false;
  code = "0000";
  isDashboardPage = false;

  constructor(
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    private router: Router,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toast: HotToastService,
    private translate: TranslateService,
    private location: Location,
    public afAuth: AngularFireAuth,
    public globalService: GlobalService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isDashboardPage = this.location.path().includes("dashboard");
      }
    });
  }

  getUsername() {
    try {
      this.username = this.userData.getUsername();
    } catch (error) {
      this.presentToast(this.translate.instant(TRANSLATION.ERROR_OCCURRED));
    }
  }

  async ngOnInit() {
    this.checkLoginStatus();
    this.listenForLoginEvents();

    this.swUpdate.versionUpdates.subscribe(async (res) => {
      if (res.type === "VERSION_DETECTED") {
        this.toast.info("Update available!");
        this.swUpdate.activateUpdate();
        window.location.reload();
      }
    });

    // login automatically
    if (await this.afAuth.authState.pipe(first()).toPromise()) {
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          this.firestore
            .doc<Profile>(`/users/${user.uid}`)
            .get()
            .toPromise()
            .then((userProfileSnapshot) => {
              if (userProfileSnapshot) {
                this.isAdmin = userProfileSnapshot.data().isAdmin
                  ? true
                  : false;
              } else {
                this.isAdmin = false;
              }
            });

          this.getUsername();
        }
      });
    }
  }

  checkLoginStatus() {
    return this.updateLoggedInStatus(this.userData.isLoggedIn());
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener("user:login", () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener("user:signup", () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener("user:logout", () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.userData.logout();
    this.router.navigateByUrl("/app/tabs/publicaciones");
    this.presentToast("Se cerro la sesion");
  }

  openMagtayani() {
    window.open("https://www.magtayani.org/", "_blank");
    /* this.iab.create("www.magtayani.org") */
  }
  /* Toast procedure------------------------------------ */
  async presentToast(message: string) {
    this.toast.info(message);
  }
  /* -------------------------------------------------- */
}
