import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
})
export class CardComponent {
  @Input() courseCode: string;
  @Input() courseTitle: string;
  @Input() notification: boolean = false;
  @Input() ongoing: boolean = true;
  @Input() subject: string;
  
  notify = "h-6 w-6 text-grey-light-1";
  
  get notifyStyle() {
    if (this.notification == true) {
      this.notify = "h-6 w-6 text-amber";
    } else {
      this.notify = "h-6 w-6 text-grey-light-1";
    }
    return this.notify;
  }

  get getColor() {
    let subjectColor = "flex items-center rounded-b-lg h-8 px-3 ";
    
    if(this.subject !== "--") {
      subjectColor = subjectColor + subjectLookup[this.subject];
    } else {
      subjectColor = subjectColor + "bg-purple-light";
    }

    return subjectColor
  }

  get cardStyle() {
    let style = "group pt-1 transition-all ease-in border rounded-lg cursor-pointer bg-white border-grey-light-1 hover:shadow hover:border-green";
    if(this.subject == "AF" || this.subject == "EL" || this.subject == "HS" || this.subject == "LA") {
      style = style + " courses_card_script";
    } else if (this.subject == "AD" || this.subject == "EC" || this.subject == "GE") {
      style = style + " courses_card_globe";
    } else {
      style = style + " courses_card";
    }

    return style;
  }
}

const subjectLookup: Record<string, string> = {
  AF: 'bg-[#F3CEC0]',
  AD: 'bg-[#DAB4E5]',
  BM: 'bg-purple-light',
  EC: 'bg-[#DAB4E5]',
  EL: 'bg-[#F3CEC0]',
  GE: 'bg-[#DAB4E5]',
  HS: 'bg-[#F3CEC0]',
  LA: 'bg-[#F3CEC0]',
  MA: 'bg-purple-light',
  ST: 'bg-purple-light'
};
