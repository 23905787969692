// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Works - pass "my-custom-class" in cssClass to increase specificity */
.my-modal-class .modal-wrapper {
  background: #222;
  width: 90%;
  height: 90%;
}

.my-small-modal-class .modal-wrapper {
  background: #222;
  width: 80%;
  height: 25%;
}

.split-pane-visible > ion-menu {
  max-width: 304px !important;
}

.comentario .modal-wrapper {
  width: 75%;
  height: auto;
  min-height: 25%;
  max-height: 50%;
  overscroll: false;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA,uEAAA;AACA;EACE,gBAAA;EACA,UAAA;EACA,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,UAAA;EACA,WAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,UAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;AACF","sourcesContent":["/* Works - pass \"my-custom-class\" in cssClass to increase specificity */\n.my-modal-class .modal-wrapper {\n  background: #222;\n  width: 90%;\n  height: 90%;\n}\n\n.my-small-modal-class .modal-wrapper {\n  background: #222;\n  width: 80%;\n  height: 25%;\n}\n\n.split-pane-visible > ion-menu {\n  max-width: 304px !important;\n}\n\n.comentario .modal-wrapper {\n  width: 75%;\n  height: auto;\n  min-height: 25%;\n  max-height: 50%;\n  overscroll: false;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
