import { Injectable, OnDestroy } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { arrayRemove, arrayUnion, increment } from "@angular/fire/firestore";
import { BehaviorSubject, Subscription, first, map } from "rxjs";
import { RawResource, Resource } from "../shared/types";

@Injectable({
  providedIn: "root",
})
export class ResourceService implements OnDestroy {
  allResources$ = new BehaviorSubject<Resource[]>([]); // all resources including those in trash
  resources$ = new BehaviorSubject<Resource[]>([]);
  resourcesSubscription: Subscription;

  collection = "Uploads";
  public loading = true;

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private auth: AngularFireAuth,
  ) {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.loadResources();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.resourcesSubscription) {
      this.resourcesSubscription.unsubscribe();
    }
  }

  parseRawResource(id: string, resource: RawResource): Resource {
    return {
      id,
      author: resource.Autor,
      date: resource.Date,
      description: resource.Grado,
      subject: resource.Materia,
      name: resource.N_Tarea,
      type: resource.Tipo,
      url: resource.URL,
      likes: resource.likes_Count,
      likedBy: resource.likedBy ?? [],
      uploader: resource.uploader,
      lastModified: resource.lastModified?.toDate() ?? new Date(),
      status: resource.status,
    };
  }

  async loadResources() {
    this.loading = true;
    this.resourcesSubscription = this.firestore
      .collection(this.collection, (ref) => ref.orderBy("Date", "desc"))
      .snapshotChanges()
      .pipe(
        map((snapshots) =>
          snapshots.map((snapshot) => {
            const id = snapshot.payload.doc.id;
            const resource = snapshot.payload.doc.data() as RawResource;
            return { id, resource };
          }),
        ),
      )
      .subscribe((resources) => {
        this.resources$.next(
          resources
            .filter(({ resource }) => resource.status !== "trash")
            .map(({ id, resource }) => this.parseRawResource(id, resource)),
        );

        this.allResources$.next(
          resources.map(({ id, resource }) =>
            this.parseRawResource(id, resource),
          ),
        );
      });
    this.loading = false;
  }

  async uploadFile(file: File) {
    const ref = this.storage.ref(`filesStorage/${new Date().getTime()}`);

    return (await ref.put(file)).metadata.fullPath;
  }

  async createResource(resource: Omit<RawResource, "URL">, file: File) {
    const URL = await this.uploadFile(file);

    if (await this.auth.authState.pipe(first()).toPromise()) {
      return this.firestore
        .collection(this.collection)
        .add({ ...resource, URL, likedBy: resource.likedBy ?? [] });
    }
  }

  async updateResource(id: string, resource: RawResource, file?: File) {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      const updatedResource: Partial<RawResource> = resource;

      if (file && file != null) {
        updatedResource.URL = await this.uploadFile(file);
      }

      await this.firestore.collection(this.collection).doc(id).set(resource);
    }
  }

  async deleteResource({ id }: Resource, when: "now" | "30-days" = "30-days") {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      const resource = this.firestore.collection(this.collection).doc(id);

      if (when === "now") {
        await resource.delete();
      } else {
        await resource.update({ status: "trash" });
      }
    }
  }

  async restoreResource(id: string) {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      const resource = this.firestore.collection(this.collection).doc(id);

      await resource.update({ status: null });
    }
  }

  async likeResource(resource: Resource) {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      const uid = (await this.auth.currentUser).uid;

      // TODO: test increment and array remove/union
      await this.firestore
        .collection(this.collection)
        .doc(resource.id)
        .ref.update({
          likes_Count: increment(1),
          likedBy: resource.likedBy.includes(uid)
            ? arrayRemove(uid)
            : arrayUnion(uid),
        });
    }
  }
}
