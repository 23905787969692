// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    firebaseConfig : {
      apiKey: "AIzaSyBFqpjS0xPtgl7kkcl-De5oHfzC1R8-31o",
      authDomain: "repositorio-dev.firebaseapp.com",
      projectId: "repositorio-dev",
      storageBucket: "repositorio-dev.appspot.com",
      messagingSenderId: "290995239495",
      appId: "1:290995239495:web:0bfbb66f29831c6bf40f62",
      measurementId: "G-T85LKNND30"
},
    name: "Dev environment"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
