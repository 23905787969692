export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyAT3ohZcRXzU_cbainfgHHHWQKwFyEW0CI",
    authDomain: "repositorio-5dd49.firebaseapp.com",
    databaseURL: "https://repositorio-5dd49-default-rtdb.firebaseio.com",
    projectId: "repositorio-5dd49",
    storageBucket: "repositorio-5dd49.appspot.com",
    messagingSenderId: "60078161888",
    appId: "1:60078161888:web:e9b8c26c13b78281de7585",
    measurementId: "G-H5D0Y33N7Y"
},
  name: 'Prod environment'
};
