import { Component, Input, OnInit } from "@angular/core";
import "@iconify/iconify";

type Icon =
  | "history-book"
  | "settings"
  | "checkmark-circle"
  | "chat"
  | "unpublish"
  | "alert"
  | "checkmark"
  | "sync"
  | "complete"
  | "incomplete"
  | "progress-alert"
  | "progress"
  | "schedule"
  | "download"
  | "mail"
  | "edit"
  | "pencil"
  | "chevron"
  | "chalkboard-teacher"
  | "paperclip"
  | "student-cap"
  | "user"
  | "user-add"
  | "institutions"
  | "trash"
  | "eye"
  | "phone"
  | "moon"
  | "sun"
  | "copy"
  | "thumbs-up"
  | "course"
  | "user-list"
  | "stars"
  | "chat-bubble"
  | "close"
  | "arrow-up"
  | "arrow-down"
  | "a-to-z"
  | "z-to-a"
  | "arrow-left"
  | "arrow-right"
  | "docx"
  | "pdf"
  | "educator-alt"
  | "student"
  | "science-and-tech"
  | "dots-y"
  | "transparent-square"
  | "heart-filled"
  | "clock-filled"
  | "clock"
  | "assignment"
  | "heart"
  | "sticky-note"
  | "leave"
  | "calendar"
  | "assignment-submission"
  | "link"
  | "computer"
  | "support"
  | "dashboard"
  | "upload"
  | "book"
  | "search"
  | "notify"
  | "forum"
  | "marker"
  | "file"
  | "close-2"
  | "filter"
  | "calculator"
  | "paint"
  | "business"
  | "script"
  | "globe"
  | "law"
  | "flag-eng"
  | "flag-spain"
  | "flag-france"
  | "gift"
  | "plus"
  | "warning-exclamination"
  | "gift"
  | "setting"
  | "science-tech";

@Component({
  selector: "app-svg-icon",
  templateUrl: "./svg-icon.component.html",
  host: { class: "block" },
})
export class SvgIconComponent implements OnInit {
  @Input() iconName = "";
  @Input() name: Icon;

  constructor() {}

  ngOnInit() {}
}
