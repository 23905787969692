import { Injectable } from '@angular/core';
import axios, { AxiosInstance } from "axios";
import { MESSENGER_CONFIG } from '../shared/messenger_config';
import {
 Student,
} from "../shared/types";
@Injectable({
  providedIn: 'root'
})

export class MessengerService {
  private readonly axios: AxiosInstance;

  MESSAGES_URL = MESSENGER_CONFIG["MESSENGER_MESSAGE_PAGE"]  
  MESSENGER_TOKEN = MESSENGER_CONFIG["MESSENGER_TOKEN"];

  HEADERS = {
    "Content-Type": "application/json",
  };
  

  constructor()
   {
    let key = this.MESSENGER_TOKEN;
    this.axios = axios.create({
      baseURL: this.MESSAGES_URL + this.MESSENGER_TOKEN,
      headers: {
        "Content-Type": "application/json",
      }
    })
  }

  async sendAnnouncement(
    message: string,
    students: Array<{ type: "facebook" | "whatsapp"; student: Student }>,
    course_name : string,
    file_url?: string,
  ) {

    let file_data = undefined;
    let TEMPLATE_DATA;
  
    if (file_url){
      let docType = undefined;

      if (MESSENGER_CONFIG.IMAGE_EXTENSIONS.some(extension =>{return file_url.toUpperCase().includes(extension)})){
        docType = "image"
      }

      if (MESSENGER_CONFIG.FILE_EXTENSIONS.some(extension =>{return file_url.toUpperCase().includes(extension)})){
        docType = "document";
      }

      if (MESSENGER_CONFIG.VIDEO_EXTENSIONS.some(extension =>{return file_url.toUpperCase().includes(extension)})){
        docType = "video";
      }

      file_data =  {
        url: file_url,
        docType: docType
      }
    }
    else{
      TEMPLATE_DATA = {
        recipient: {
          id: null,
        },
        messaging_type: "MESSAGE_TAG",
        //messaging_type: "RESPONSE",
        tag: "CONFIRMED_EVENT_UPDATE", //to be reviewed
        message: {
          text: message,
        },
      };
    }

    await this.sendTemplatedMessage(
      students //students already filtered
        .map(({ student }) => ({
          destination: student.messenger_id,
          id: course_name,
        })),
      TEMPLATE_DATA,
      //file_data
    );
  }

  async sendTemplatedMessage(
    receivers: Array<{ destination: string; id: string;}>,
    TEMPLATE_DATA : any,
    //file_data?: {url: string, docType: string},
  ) {
    console.log(this.MESSAGES_URL + this.MESSENGER_TOKEN)
    for (let { destination, id} of receivers) {
      let data = TEMPLATE_DATA
      data.recipient.id = destination;
      await this.axios.post(  
        this.MESSAGES_URL + this.MESSENGER_TOKEN,
        JSON.stringify(data),
        {
          headers: {
            apikey: this.MESSENGER_TOKEN,
          },
        },
      ).then((response)=> console.log(response.status))
      .catch((err)=> console.log(err));
    }
  }


  async send_templated_assignment(url: string, uid : string, media_type : string) {
    let receivers = new Array();
    receivers.push({destination: uid, id : "additional parameter"});

    //PDF not compatible
    if (media_type == "pdf"){
      media_type = "file"
    }

    let TEMPLATE_DATA = {
      recipient: {
        id: uid,
      },
      messaging_type: "MESSAGE_TAG",
      tag: "CONFIRMED_EVENT_UPDATE", //to be reviewed
      message: {
        attachment: {
          type: media_type, 
          payload: {
            url: url,
            is_reusable: true
          },
        },
      },
    };

    await this.sendTemplatedMessage(
      receivers,
      TEMPLATE_DATA,
    );

  }


  async sendGradeMessage(
    message: string,
    course_id: string,
    students: Array<{ type: "facebook" | "whatsapp"; student: Student }>,
  ) {

    let receivers = new Array();
    receivers.push({destination: students[0].student.messenger_id, id : "additional parameter"});

    //language //TODO
    let TEMPLATE_DATA = {
      recipient: {
        id: null,
      },
      messaging_type: "MESSAGE_TAG",
      //messaging_type: "RESPONSE",
      tag: "CONFIRMED_EVENT_UPDATE", //to be reviewed
      message: {
        text: `Your grade in your assignment of the course ${course_id} is ${message}.`,
      },
    };

    await this.sendTemplatedMessage(
      receivers,
      TEMPLATE_DATA
    )
  }

  async sendComment(
    message: string,
    student: Student,
    language: string //TODO
  ) {

    let receivers = new Array();
    receivers.push({destination: student.messenger_id, id : "additional parameter"});
    console.log(receivers)


    let TEMPLATE_DATA = {
      recipient: {
        id: null,
      },
      messaging_type: "MESSAGE_TAG",
      //messaging_type: "RESPONSE",
      tag: "CONFIRMED_EVENT_UPDATE", //to be reviewed
      message: {
        text: message,
      },
    };

    await this.sendTemplatedMessage(
      receivers,
      TEMPLATE_DATA
    )
  }

}
