import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
})
export class BannerComponent {
    @Input() title: string;
    @Input() description: string;
    @Input() type: 'warning' | 'error' | 'information' = 'information';
    @Output() closeBanner: EventEmitter<void> = new EventEmitter<void>();

    onClose(): void {
        this.closeBanner.emit();
    }
}
