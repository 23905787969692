import { Component, Input, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { FirebaseService } from "src/app/services/firebase.service";
import { GlobalService } from "src/app/services/global.service";
import { UserData } from "src/app/shared/user-data";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  @Input()
  title: string = "DASHBOARD";
  @Input()
  loggedIn = false;

  path: string;
  id: string;
  username: string;
  profile_image: string;
  image_behavior: BehaviorSubject<string>;
  userType: number = +localStorage.getItem("userType");
  constructor(
    private translate: TranslateService,
    public globalService: GlobalService,
    public router: Router,
    public FS: FirebaseService,
    public userData: UserData,
    public firestore: AngularFireStorage,
    public auth: AngularFireAuth,
    public firebaseService: FirebaseService,
  ) {}

  ngOnInit() {
    this.router.events.subscribe(async () => {
      const index = this.router.url.split("/").length > 2 ? 2 : 1;
      this.path = this.router.url.split("/")[index].toUpperCase();

      if (this.path.includes("#")) {
        this.title = await this.translate.instant(
          this.path.split("#")[1].replace("-", "_"),
        );
      } else {
        this.title = `${this.path
          .substring(0, 1)
          .toUpperCase()}${this.path.substring(1)}`;
      }
    });

    this.userData.image_behavior.subscribe((new_value) => {
      this.profile_image = new_value;
    });

    this.checkProfileImage();
  }

  checkProfileImage() {
    this.profile_image =
      "https://api.dicebear.com/6.x/fun-emoji/svg?seed='{{ username }}'";

    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.id = user.uid;
        this.username = user.email;
        this.profile_image = this.userData.getProfileImage(
          await this.auth.currentUser,
        );
      } else {
        this.profile_image = undefined;
        this.username = undefined;
      }
    });
  }

  toggleSidebar() {
    this.globalService.isSidebarOpen = !this.globalService.isSidebarOpen;
  }

  async logout() {
    await this.FS.logout();
    //await this.userData.logout();
    await this.router.navigateByUrl("/");
  }
}
