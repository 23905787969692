import { Injectable } from "@angular/core";
import { User } from "@angular/fire/auth";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  constructor(
    private router: Router,
    private auth: AngularFireAuth,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.auth.onAuthStateChanged((user: User) => {
        if (user) {
          resolve(true);
        } else {
          this.router.navigate(["/"]);
          resolve(false);
        }
      });
    });
  }
}
