import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GlobalService } from "src/app/services/global.service";

@Component({
  selector: "settings-panel",
  templateUrl: "./settings-panel.component.html",
})
export class SettingsPanelComponent {
  constructor(
    public route: ActivatedRoute,
    public globalService: GlobalService,
  ) {}
}
