import { Injectable, OnDestroy } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { BehaviorSubject, Subscription, first } from "rxjs";
import { map } from "rxjs/operators";
import { RawStudent, Student } from "../shared/types";
import { CourseService } from "./course.service";
import { FirebaseService } from "./firebase.service";
import { GupshupService } from "./gupshup.service";

@Injectable({
  providedIn: "root",
})
export class StudentService implements OnDestroy {
  students$: BehaviorSubject<Student[]> = new BehaviorSubject([]);
  studentsClassList$: BehaviorSubject<Student[]> = new BehaviorSubject([]);
  studentsSubscription: Subscription;
  teacherCode: string;

  collection = "alumnos";
  loading = true;

  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private FS: FirebaseService,
    private GS: GupshupService,
    private CS: CourseService,
  ) {
    this.initialize();
  }

  ngOnDestroy(): void {
    if (this.studentsSubscription) {
      this.studentsSubscription.unsubscribe();
    }
  }

  initialize() {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (this.teacherCode == undefined) {
          this.teacherCode = await this.FS.get_teacher_code();
        }
        if (+localStorage.getItem("userType") === 2) {
          await this.loadStudent(localStorage.getItem("userDocId"));
        } else {
          await this.loadStudents();
        }
      } else {
        this.teacherCode = undefined;
      }
    });
  }

  parseRawStudent(id: string, student: RawStudent): Student {
    let arr_str = student.codigo;

    return {
      id,
      name: student.nombre,
      phone: student.phone,
      code: student.codigo,
      codes_array: student.codes_array,
      codes_array_str: student.codes_array?.join(","),
      isVerified: student.verificado,
      language: student.language ? student.language : "ENGLISH",
      messenger_id: student.messenger_id ? student.messenger_id : null,
      note: student.note ? student.note : null
    };
  }

  async addStudent(student: RawStudent) {
    //HACK initilize array of codes
    student.codes_array = [student.codigo];
    await this.firestore.collection(this.collection).add(student);
    await this.GS.opt_user(student.phone, "in");
  }

  async getStudentData(searchParam: string) {
    const snapshots = await this.firestore
      .collection<RawStudent>(this.collection, (ref) =>
        ref.where("nombre", "==", searchParam),
      )
      .get()
      .toPromise();
    const id = snapshots.docs[0]?.id;
    const student = snapshots.docs[0]?.data() as RawStudent;
    return { id, student };
    // return snapshots.docs[0]?.data();
  }

  async loadStudents() {
    this.loading = true;

    let codes_array = await (
      await this.CS.getCourses(this.teacherCode)
    ).map((course) => {
      return this.teacherCode + "-" + course.id;
    });
    if (codes_array.length === 0) {
      codes_array.push("");
    }
    this.studentsSubscription = this.firestore
      .collection<RawStudent>(this.collection, (ref) =>
        ref.where("codes_array", "array-contains-any", codes_array),
      )
      .snapshotChanges()
      .pipe(
        map((snapshots) =>
          snapshots.map((snapshot) => {
            const id = snapshot.payload.doc.id;
            const student = snapshot.payload.doc.data() as RawStudent;
            return { id, student };
          }),
        ),
      )
      .subscribe((students) => {
        this.students$.next(
          students.map(({ id, student }) => this.parseRawStudent(id, student)),
        );
      });

    this.loading = false;
  }

  async loadStudent(id: string): Promise<Student[]> {
    this.loading = true;

    try {
      const snapshot = await this.firestore
        .collection<RawStudent>(this.collection)
        .doc(id)
        .get()
        .toPromise();

      if (snapshot.exists) {
        const rawStudent = snapshot.data() as RawStudent;
        const students = [this.parseRawStudent(id, rawStudent)];
        this.students$.next(students);
        localStorage.setItem(
          "studentCourseCodeArray",
          this.students$.getValue()[0]?.codes_array_str,
        );
        return students;
      } else {
        this.students$.next([]);
        return [];
      }
    } catch (error) {
      console.error("Error loading student:", error);
      throw error; // Propagate the error to the caller
    } finally {
      this.loading = false;
    }
  }

  async loadStudentsClassList(codes_array: string[]) {
    this.firestore
      .collection<RawStudent>(this.collection, (ref) =>
        ref.where("codes_array", "array-contains-any", codes_array),
      )
      .snapshotChanges()
      .pipe(
        map((snapshots) =>
          snapshots.map((snapshot) => {
            const id = snapshot.payload.doc.id;
            const student = snapshot.payload.doc.data() as RawStudent;
            return { id, student };
          }),
        ),
      )
      .subscribe((students) => {
        this.studentsClassList$.next(
          students.map(({ id, student }) => this.parseRawStudent(id, student)),
        );
      });
  }

  async updateNote(id: string, student: RawStudent, note?: string) {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      const updatedStudent: Partial<RawStudent> = student;

      if (note && note != null) {
        updatedStudent.note = note;
      }

      await this.firestore.collection(this.collection).doc(id).set(student);
    }
  }
}
