import { Component, Input} from "@angular/core";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
})
export class BreadcrumbComponent {
  @Input() label: string;
  @Input() icon: any;
  @Input() active: boolean = false;
  @Input() backslash: boolean = false;

}
