import { Component } from "@angular/core";
import { AssignmentService } from "src/app/services/assignment.service";
import { CourseService } from "src/app/services/course.service";
import { ForumService } from "src/app/services/forum.service";
import { GlobalService } from "src/app/services/global.service";
import { ResourceService } from "src/app/services/resource.service";
import { StudentService } from "src/app/services/student.service";

@Component({
  selector: "dashboard-panel",
  templateUrl: "./dashboard-panel.component.html",
})
export class DashboardPanelComponent {
  constructor(
    public globalService: GlobalService,
    public courseService: CourseService,
    public assignmentService: AssignmentService,
    public studentService: StudentService,
    public resourceService: ResourceService,
    public forumService: ForumService,
  ) {}
}
