import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GlobalService } from "src/app/services/global.service";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
})
export class NavComponent implements OnInit {
  @ViewChild("loginModal") loginModal: ModalComponent;
  @ViewChild("signupModal", { static: true }) signupModal: ModalComponent;
  @ViewChild("donateModal") donateModal: ModalComponent;

  @Input() dark: boolean;
  @Input() page: "home" | "about-us" | "courses" | "contact";
  signupType: "educator" | "learner" | undefined;
  languageDropdownOpen = false;
  languages = [
    { name: "English", code: "en", icon: "flag-eng" },
    { name: "Spanish", code: "es", icon: "flag-spain" },
    { name: "French", code: "fr", icon: "flag-france" },
  ];
  canadaHelpsLink: string = "https://www.canadahelps.org/en/dn/m/78944?v2=true";
  givingBlockLink: string = "https://thegivingblock.com/donate/indigenous-friends-association/";
  paypalLink: string = "https://www.paypal.com/donate/?hosted_button_id=RJDGGS4MS9W8L";

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    public globalService: GlobalService,
  ) {}

  ngOnInit() {
    const {
      name,
      number,
      messenger_id,
    }: {
      name?: string;
      number?: string;
      messenger_id?: string;
    } = this.route.snapshot.queryParams;

    if (name || number || messenger_id) {
      this.signupModal.open();
    }
  }

  addMainMenuClass() {
    document.body.classList.add("home_menu_open");
  }

  removeMainMenuClass() {
    document.body.classList.remove("home_menu_open");
  }

  createChangesAndReturn(): number {   
    // Defining this function to manually trigger change detection as Angular's default change detection mechanism may not detect changes when the template for signup is closed. By invoking this function, we ensure that any changes made within the signup component are propagated and reflected in the parent component's view.
    return Math.floor(Math.random() * 10);
  }
}
