// Do not modify this file directly!!!
// This file was automatically generated using the auto-translate script (src/app/translations/auto-translate.ts).
// If you need to make changes to the translations, modify the translations.json file (src/app/translations/translations.json) and rerun the npm "translate" script.

export enum SPEAKABLE_LANGUAGE {
  en = "en",
  fr = "fr",
  es = "es",
}

export enum LANGUAGE {
  en = "en",
  fr = "fr",
  es = "es",
  DEBUG = "DEBUG",
}

export const DEBUG_TRANSLATION_TEXT = "DEBUG-T";

export enum TRANSLATION {
  INTRO_MESSAGE = "INTRO_MESSAGE",
  WITH = "WITH",
  SUBMIT = "SUBMIT",
  SUBMISSIONS = "SUBMISSIONS",
  LEARN = "LEARN",
  WOLIMITS = "WOLIMITS",
  TAKE_LEARNING = "TAKE_LEARNING",
  EMPOWER_INSTITUTION = "EMPOWER_INSTITUTION",
  LEARN_EXPERIENCES = "LEARN_EXPERIENCES",
  DESCRIPTION = "DESCRIPTION",
  REGISTER_LEARNER = "REGISTER_LEARNER",
  REGISTER_EDUCATOR = "REGISTER_EDUCATOR",
  LEARNER = "LEARNER",
  INSTITUTION = "INSTITUTION",
  FINGERTIPS = "FINGERTIPS",
  ACCESSIBLE = "ACCESSIBLE",
  FINISH = "FINISH",
  EMPOWERING = "EMPOWERING",
  HOME_ELEARNING = "HOME_ELEARNING",
  HOME_ELEARNING2 = "HOME_ELEARNING2",
  LEARN_ANYTIME = "LEARN_ANYTIME",
  MAGTAYANI_YOUTH_GROUP = "MAGTAYANI_YOUTH_GROUP",
  HOME = "HOME",
  FIRST_NAMES = "FIRST_NAMES",
  LAST_NAMES = "LAST_NAMES",
  PASSWORD = "PASSWORD",
  AGREE_TERMS_1 = "AGREE_TERMS_1",
  CONTINUE_WITH = "CONTINUE_WITH",
  ALREADY_HAVE_AN_ACCOUNT = "ALREADY_HAVE_AN_ACCOUNT",
  SIGNUP = "SIGNUP",
  REGISTERED = "REGISTERED",
  COURSES = "COURSES",
  REGISTERED_COURSES = "REGISTERED_COURSES",
  REGISTER_EXTERNAL = "REGISTER_EXTERNAL",
  SELECT_SCHOOL_CODE = "SELECT_SCHOOL_CODE",
  CHOOSE_ACCOUNT_TYPE = "CHOOSE_ACCOUNT_TYPE",
  ITEM = "ITEM",
  DATE_MODIFIED = "DATE_MODIFIED",
  SELECT = "SELECT",
  CANCEL = "CANCEL",
  UNKNOWN = "UNKNOWN",
  ADD_SCHOOL = "ADD_SCHOOL",
  ADD_SCHOOL_MESSAGE = "ADD_SCHOOL_MESSAGE",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  INVALID_EMAIL = "INVALID_EMAIL",
  MISSING_EMAIL = "MISSING_EMAIL",
  UPLOAD_FILES = "UPLOAD_FILES",
  FILENAME = "FILENAME",
  HIDE = "HIDE",
  SHOW = "SHOW",
  GRADE = "GRADE",
  GRADES = "GRADES",
  COMMENTS = "COMMENTS",
  COURSE = "COURSE",
  OR = "OR",
  CURRENT_COURSES = "CURRENT_COURSES",
  UPDATE_COURSE = "UPDATE_COURSE",
  UPDATE_ASSIGNMENT = "UPDATE_ASSIGNMENT",
  FILL_ALL_FIELDS = "FILL_ALL_FIELDS",
  ERROR_OCCURRED = "ERROR_OCCURRED",
  UPLOADED_BY = "UPLOADED_BY",
  UPLOADED = "UPLOADED",
  PHONE = "PHONE",
  ACTIONS = "ACTIONS",
  NOW = "NOW",
  SCHOOL_REGISTRATION = "SCHOOL_REGISTRATION",
  SCHOOL_NAME = "SCHOOL_NAME",
  HAVE_ACCOUNT = "HAVE_ACCOUNT",
  STATE = "STATE",
  STATUS = "STATUS",
  SUBMITTED = "SUBMITTED",
  UNSUBMITTED = "UNSUBMITTED",
  DRAFT = "DRAFT",
  LATE = "LATE",
  FILES_SUBMITTED = "FILES_SUBMITTED",
  SCHOOL_REGISTRATION_BUTTON = "SCHOOL_REGISTRATION_BUTTON",
  MY_STUDENTS = "MY_STUDENTS",
  FOR_STUDENTS = "FOR_STUDENTS",
  STUDENTS = "STUDENTS",
  FOR_EDUCATORS = "FOR_EDUCATORS",
  EDUCATORS = "EDUCATORS",
  LOADING_STUDENTS = "LOADING_STUDENTS",
  LOADING = "LOADING",
  ADD_STUDENT = "ADD_STUDENT",
  ADD_STUDENT_NOTE = "ADD_STUDENT_NOTE",
  ADD_STUDENT_NAME = "ADD_STUDENT_NAME",
  ADD_STUDENT_CODE = "ADD_STUDENT_CODE",
  ADD_STUDENT_PHONE = "ADD_STUDENT_PHONE",
  DELETE = "DELETE",
  SEND = "SEND",
  MESSAGE = "MESSAGE",
  ACCOUNT = "ACCOUNT",
  USERS = "USERS",
  STUDENT_CODE = "STUDENT_CODE",
  REASON_FOR_CONTACT = "REASON_FOR_CONTACT",
  GENERAL_FEEDBACK = "GENERAL_FEEDBACK",
  ISSUES_COMPLAINTS = "ISSUES_COMPLAINTS",
  FEATURE_REQUEST = "FEATURE_REQUEST",
  DESCRIPTION_INQUIRY = "DESCRIPTION_INQUIRY",
  REASON_REQUIRED = "REASON_REQUIRED",
  DESCRIPTION_REQUIRED = "DESCRIPTION_REQUIRED",
  SUBJECT = "SUBJECT",
  CODE = "CODE",
  FORGOT_MY_PASSWORD = "FORGOT_MY_PASSWORD",
  SUBMIT_HOMEOWRK = "SUBMIT_HOMEOWRK",
  ACTIVITY_SUMMARY = "ACTIVITY_SUMMARY",
  ACCOUNT_NAME = "ACCOUNT_NAME",
  NUMBER_OF_STUDENTS = "NUMBER_OF_STUDENTS",
  REGISTERED_STUDENTS = "REGISTERED_STUDENTS",
  REGISTERED_EDUCATORS = "REGISTERED_EDUCATORS",
  SUBMITTED_ASSIGNMENTS = "SUBMITTED_ASSIGNMENTS",
  EXPLORE_COURSES = "EXPLORE_COURSES",
  BECOME_EDUCATOR = "BECOME_EDUCATOR",
  HOME_TEXT_LIVES = "HOME_TEXT_LIVES",
  REMEMBER_ME = "REMEMBER_ME",
  HOME_TEXT = "HOME_TEXT",
  ICONNECTED = "ICONNECTED",
  ICONNECTED2 = "ICONNECTED2",
  SEND_SUGGESTIONS = "SEND_SUGGESTIONS",
  RECEIVED_TASK = "RECEIVED_TASK",
  ASSIGNMENT_GRADE = "ASSIGNMENT_GRADE",
  PHONE_NUMBER = "PHONE_NUMBER",
  AUTHOR = "AUTHOR",
  ADDRESS = "ADDRESS",
  QUICK_LINKS = "QUICK_LINKS",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  TERMS_CONDITIONS = "TERMS_CONDITIONS",
  SIGNIN = "SIGNIN",
  SIGNIN_TITLE = "SIGNIN_TITLE",
  SIGNIN_EMAIL = "SIGNIN_EMAIL",
  SIGNIN_FORGOT_PASSWORD = "SIGNIN_FORGOT_PASSWORD",
  SCHOOL_GRADE = "SCHOOL_GRADE",
  SEND_COMMENT = "SEND_COMMENT",
  FAVOURITES = "FAVOURITES",
  COMMUNITY_UPLOADS = "COMMUNITY_UPLOADS",
  MY_UPLOADS = "MY_UPLOADS",
  SEARCH_PUBLICATIONS = "SEARCH_PUBLICATIONS",
  CREATE_RESOURCE = "CREATE_RESOURCE",
  PUBLICATION_AUTHOR = "PUBLICATION_AUTHOR",
  PUBLICATION_SUBJECT = "PUBLICATION_SUBJECT",
  TYPE = "TYPE",
  PUBLICATION_DATE = "PUBLICATION_DATE",
  WELCOME_TEACHER = "WELCOME_TEACHER",
  ABOUT_US = "ABOUT_US",
  CONTACT_NUMBER = "CONTACT_NUMBER",
  CONTACT_INFO = "CONTACT_INFO",
  CONTACT = "CONTACT",
  LOADING_COURSES = "LOADING_COURSES",
  MY_COURSES = "MY_COURSES",
  ASSIGNMENTS = "ASSIGNMENTS",
  DASHBOARD = "DASHBOARD",
  RECENT_COURSES = "RECENT_COURSES",
  LATEST_COURSES = "LATEST_COURSES",
  LOGIN_REGISTER = "LOGIN_REGISTER",
  LOGIN = "LOGIN",
  PREVIOUSLY_PLT = "PREVIOUSLY_PLT",
  LOGOUT = "LOGOUT",
  GET_STARTED = "GET_STARTED",
  ABOUT_US_INTRO = "ABOUT_US_INTRO",
  ABOUT_US_DESCRIPTION = "ABOUT_US_DESCRIPTION",
  ABOUT_US_TOOLS_TITLE = "ABOUT_US_TOOLS_TITLE",
  ABOUT_US_TOOLS_DELIVERY = "ABOUT_US_TOOLS_DELIVERY",
  ABOUT_US_TOOLS_FORUM = "ABOUT_US_TOOLS_FORUM",
  ABOUT_US_TOOLS_PUBLICATION = "ABOUT_US_TOOLS_PUBLICATION",
  ABOUT_US_TOOLS_SPACE = "ABOUT_US_TOOLS_SPACE",
  ABOUT_US_OTHER_PROJECTS = "ABOUT_US_OTHER_PROJECTS",
  ADDED_SUCCESSFULLY = "ADDED_SUCCESSFULLY",
  MISSING_INFO = "MISSING_INFO",
  ADDED_SCHOOL = "ADDED_SCHOOL",
  TRY_AGAIN = "TRY_AGAIN",
  STUDENT_DELISTED = "STUDENT_DELISTED",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  RESET_EMAIL = "RESET_EMAIL",
  CONFIRM_ON_WHATSAPP = "CONFIRM_ON_WHATSAPP",
  REGISTERATION_RECEIEVED = "REGISTERATION_RECEIEVED",
  NAME = "NAME",
  ACTION_CONFIRM = "ACTION_CONFIRM",
  EMAIL_NOT_FOUND = "EMAIL_NOT_FOUND",
  WAIT_WHILE = "WAIT_WHILE",
  IS_UPLOADING = "IS_UPLOADING",
  RESTORE = "RESTORE",
  TRASH = "TRASH",
  RESTORED_FROM_TRASH = "RESTORED_FROM_TRASH",
  IS_BEING_RESTORED = "IS_BEING_RESTORED",
  IS_BEING_UNPUBLISH = "IS_BEING_UNPUBLISH",
  SUCCESSFULLY_UNPUBLISH = "SUCCESSFULLY_UNPUBLISH",
  IS_DELETING = "IS_DELETING",
  NEW_TASK = "NEW_TASK",
  HOMEWORK_SENT = "HOMEWORK_SENT",
  MOVED_TO_TRASH = "MOVED_TO_TRASH",
  DELETED_ITEM_TITLE = "DELETED_ITEM_TITLE",
  DELETED_ITEM_SUBTITLE = "DELETED_ITEM_SUBTITLE",
  DELETED_ITEM_MESSAGE = "DELETED_ITEM_MESSAGE",
  INFORMATION = "INFORMATION",
  TOOLS = "TOOLS",
  SETTINGS = "SETTINGS",
  UNDO_CHANGES = "UNDO_CHANGES",
  SAVE_CHANGES = "SAVE_CHANGES",
  PERSONAL_INFORMATION = "PERSONAL_INFORMATION",
  APPEARANCE = "APPEARANCE",
  THEME = "THEME",
  CHANGE_THEME = "CHANGE_THEME",
  LIGHT = "LIGHT",
  DARK = "DARK",
  SYSTEM = "SYSTEM",
  LANGUAGE = "LANGUAGE",
  ENGLISH = "ENGLISH",
  SPANISH = "SPANISH",
  FRENCH = "FRENCH",
  CHANGE_DEFAULT_LANGUAGE = "CHANGE_DEFAULT_LANGUAGE",
  SELECTED = "SELECTED",
  DARK_MODE = "DARK_MODE",
  MY_TEACHERS = "MY_TEACHERS",
  FORUM = "FORUM",
  FORUMS = "FORUMS",
  RESOURCES = "RESOURCES",
  RESOURCE = "RESOURCE",
  SUPPORT = "SUPPORT",
  COMMENT = "COMMENT",
  COMMENT2 = "COMMENT2",
  SETTINGS_UPDATE = "SETTINGS_UPDATE",
  SETTINGS_HEADER = "SETTINGS_HEADER",
  STUDENT_DELETE = "STUDENT_DELETE",
  FEEDBACK_SENT = "FEEDBACK_SENT",
  NOTIFICATION_ENABLE = "NOTIFICATION_ENABLE",
  ERROR_OCCURRED_REPORT = "ERROR_OCCURRED_REPORT",
  LOADING_TASK = "LOADING_TASK",
  NEED_GRADING = "NEED_GRADING",
  RIGHTS_RESERVED = "RIGHTS_RESERVED",
  POST = "POST",
  POST_NEW_THREAD = "POST_NEW_THREAD",
  POST_BY = "POST_BY",
  CREATE_THREAD = "CREATE_THREAD",
  OPEN = "OPEN",
  OPEN_SUBMISSION = "OPEN_SUBMISSION",
  PENDING = "PENDING",
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
  BODY = "BODY",
  TITLE = "TITLE",
  SEARCH_FORUMS = "SEARCH_FORUMS",
  ADD_COMMENT = "ADD_COMMENT",
  ADD_ASSIGNMENT = "ADD_ASSIGNMENT",
  ADD_COURSE = "ADD_COURSE",
  COMING_SOON = "COMING_SOON",
  SETTINGS_TITLE = "SETTINGS_TITLE",
  SETTINGS_WARNING = "SETTINGS_WARNING",
  SETTINGS_TEXT = "SETTINGS_TEXT",
  EMAIL = "EMAIL",
  PUSH_NOTIFICATIONS = "PUSH_NOTIFICATIONS",
  MORE_ACTIVITY = "MORE_ACTIVITY",
  REMINDERS = "REMINDERS",
  TAGS = "TAGS",
  SETTINGS_TEXT_TAGS = "SETTINGS_TEXT_TAGS",
  SETTINGS_TEXT_REMINDERS = "SETTINGS_TEXT_REMINDERS",
  SETTINGS_TEXT_ACTIVITY = "SETTINGS_TEXT_ACTIVITY",
  EDUCATOR = "EDUCATOR",
  ABOUT_US_HEADER = "ABOUT_US_HEADER",
  ABOUT_US_TEXT1 = "ABOUT_US_TEXT1",
  ABOUT_US_TEXT2_1 = "ABOUT_US_TEXT2_1",
  ABOUT_US_TEXT2_2 = "ABOUT_US_TEXT2_2",
  ABOUT_US_HEADER2 = "ABOUT_US_HEADER2",
  ABOUT_US_BLOCK_TITLE = "ABOUT_US_BLOCK_TITLE",
  ABOUT_US_BLOCK_SUBTITLE = "ABOUT_US_BLOCK_SUBTITLE",
  LEARNING_LIFE_LONG = "LEARNING_LIFE_LONG",
  ABOUT_US_BLOCK_BODY_HEADER1 = "ABOUT_US_BLOCK_BODY_HEADER1",
  ABOUT_US_BLOCK_BODY_TEXT1 = "ABOUT_US_BLOCK_BODY_TEXT1",
  ABOUT_US_BLOCK_BODY_HEADER2 = "ABOUT_US_BLOCK_BODY_HEADER2",
  ABOUT_US_BLOCK_BODY_TEXT2 = "ABOUT_US_BLOCK_BODY_TEXT2",
  ABOUT_US_BLOCK_BODY_HEADER3 = "ABOUT_US_BLOCK_BODY_HEADER3",
  ABOUT_US_BLOCK_BODY_TEXT3 = "ABOUT_US_BLOCK_BODY_TEXT3",
  ABOUT_US_BLOCK_BODY_HEADER4 = "ABOUT_US_BLOCK_BODY_HEADER4",
  ABOUT_US_BLOCK_BODY_TEXT4 = "ABOUT_US_BLOCK_BODY_TEXT4",
  ABOUT_US_FOR_STUDENTS_HEADER = "ABOUT_US_FOR_STUDENTS_HEADER",
  ABOUS_US_BLOCK2_TEXT1 = "ABOUS_US_BLOCK2_TEXT1",
  ABOUT_US_BLOCK2_HEADER2 = "ABOUT_US_BLOCK2_HEADER2",
  ABOUT_US_BLOCK2_TEXT2 = "ABOUT_US_BLOCK2_TEXT2",
  ABOUT_US_BLOCK2_HEADER3 = "ABOUT_US_BLOCK2_HEADER3",
  ABOUT_US_BLOCK2_TEXT3 = "ABOUT_US_BLOCK2_TEXT3",
  ABOUT_US_BLOCK2_HEADER4 = "ABOUT_US_BLOCK2_HEADER4",
  ABOUT_US_BLOCK2_TEXT4 = "ABOUT_US_BLOCK2_TEXT4",
  ABOUT_US_BLOCK3_TITLE = "ABOUT_US_BLOCK3_TITLE",
  ABOUT_US_BLOCK3_HEADER1 = "ABOUT_US_BLOCK3_HEADER1",
  CLOSE = "CLOSE",
  ABOUT_US_BLOCK3_TEXT1 = "ABOUT_US_BLOCK3_TEXT1",
  ABOUT_US_BLOCK3_HEADER2 = "ABOUT_US_BLOCK3_HEADER2",
  ABOUT_US_BLOCK3_TEXT2 = "ABOUT_US_BLOCK3_TEXT2",
  ABOUT_US_BLOCK3_HEADER3 = "ABOUT_US_BLOCK3_HEADER3",
  ABOUT_US_BLOCK3_TEXT3 = "ABOUT_US_BLOCK3_TEXT3",
  ABOUT_US_BLOCK3_HEADER4 = "ABOUT_US_BLOCK3_HEADER4",
  ABOUT_US_BLOCK3_TEXT4 = "ABOUT_US_BLOCK3_TEXT4",
  ABOUT_US_BLOCK3_HEADER5 = "ABOUT_US_BLOCK3_HEADER5",
  ABOUT_US_BLOCK3_TEXT5 = "ABOUT_US_BLOCK3_TEXT5",
  ABOUT_US_FOR_EDUCATORS_HEADER = "ABOUT_US_FOR_EDUCATORS_HEADER",
  ABOUT_US_BLOCK4_HEADER1 = "ABOUT_US_BLOCK4_HEADER1",
  ABOUT_US_BLOCK4_TEXT1 = "ABOUT_US_BLOCK4_TEXT1",
  ABOUT_US_BLOCK4_HEADER2 = "ABOUT_US_BLOCK4_HEADER2",
  ABOUT_US_BLOCK4_TEXT2 = "ABOUT_US_BLOCK4_TEXT2",
  ABOUT_US_BLOCK4_HEADER3 = "ABOUT_US_BLOCK4_HEADER3",
  ABOUT_US_BLOCK4_TEXT3 = "ABOUT_US_BLOCK4_TEXT3",
  ABOUT_US_BLOCK4_HEADER4 = "ABOUT_US_BLOCK4_HEADER4",
  ABOUT_US_BLOCK4_TEXT4 = "ABOUT_US_BLOCK4_TEXT4",
  CONNECT_CONTACT = "CONNECT_CONTACT",
  ABOUT_US_FULL_DESCRIPTION = "ABOUT_US_FULL_DESCRIPTION",
  ABOUT_US_LEGEND = "ABOUT_US_LEGEND",
  GET_ACCESS = "GET_ACCESS",
  ABOUT_US_TEXT_4 = "ABOUT_US_TEXT_4",
  ABOUT_US_TEXT_5_1 = "ABOUT_US_TEXT_5_1",
  ABOUT_US_TEXT_5_2 = "ABOUT_US_TEXT_5_2",
  EMAIL_ADDRESS = "EMAIL_ADDRESS",
  EMAIL_REQUIRED = "EMAIL_REQUIRED",
  NAME_REQUIRED = "NAME_REQUIRED",
  EMAIL_VALID = "EMAIL_VALID",
  JUST_REGISTER = "JUST_REGISTER",
  SUBSCRIBE = "SUBSCRIBE",
  WAS = "WAS",
  UPDATED = "UPDATED",
  CREATED = "CREATED",
  SUCCESSFULLY = "SUCCESSFULLY",
  THIS_ACTION_IS = "THIS_ACTION_IS",
  ASSIGNMENT_HAS_BEEN_UPDATED = "ASSIGNMENT_HAS_BEEN_UPDATED",
  ASSIGNMENT_HAS_BEEN_MADE = "ASSIGNMENT_HAS_BEEN_MADE",
  PUBLISH_ASSIGNMENT = "PUBLISH_ASSIGNMENT",
  CREATED_ON = "CREATED_ON",
  ASSIGNMENT = "ASSIGNMENT",
  DOWNLOAD_FILE = "DOWNLOAD_FILE",
  FILE_NOT_SUPPORTED = "FILE_NOT_SUPPORTED",
  PARTNERSHIP_WITH = "PARTNERSHIP_WITH",
  EDIT_COURSE = "EDIT_COURSE",
  COURSE_SUBJECT = "COURSE_SUBJECT",
  AF_SUBJECT = "AF_SUBJECT",
  AD_SUBJECT = "AD_SUBJECT",
  BM_SUBJECT = "BM_SUBJECT",
  EC_SUBJECT = "EC_SUBJECT",
  EL_SUBJECT = "EL_SUBJECT",
  GE_SUBJECT = "GE_SUBJECT",
  HS_SUBJECT = "HS_SUBJECT",
  LA_SUBJECT = "LA_SUBJECT",
  MA_SUBJECT = "MA_SUBJECT",
  ST_SUBJECT = "ST_SUBJECT",
  START_DATE = "START_DATE",
  DUE_DATE = "DUE_DATE",
  SEARCH_HERE = "SEARCH_HERE",
  EDUCATOR_CODE = "EDUCATOR_CODE",
  ENROLLED_COURSES = "ENROLLED_COURSES",
  SOCIAL = "SOCIAL",
  EDUCATIONAL_FEEDBACK = "EDUCATIONAL_FEEDBACK",
  PLATFORM_FEEDBACK = "PLATFORM_FEEDBACK",
  COURSE_CODE = "COURSE_CODE",
  CLASS_SIZE = "CLASS_SIZE",
  COURSE_NAME = "COURSE_NAME",
  COURSE_LENGTH_MESSAGE = "COURSE_LENGTH_MESSAGE",
  COURSE_EXIST_MESSAGE = "COURSE_EXIST_MESSAGE",
  COURSE_ID = "COURSE_ID",
  COURSE_STAFF = "COURSE_STAFF",
  NO_ENROLLED_STUDENTS_1 = "NO_ENROLLED_STUDENTS_1",
  NO_ENROLLED_STUDENTS_2 = "NO_ENROLLED_STUDENTS_2",
  NO_COURSES = "NO_COURSES",
  NO_STATS = "NO_STATS",
  ASSIGNMENT_CREATION_LEGEND = "ASSIGNMENT_CREATION_LEGEND",
  COURSE_SIDEBAR_MESSAGE = "COURSE_SIDEBAR_MESSAGE",
  ANNOUNCEMENTS = "ANNOUNCEMENTS",
  POSTED = "POSTED",
  NO_ANNOUNCEMENTS = "NO_ANNOUNCEMENTS",
  NO_POSTED = "NO_POSTED",
  CONFIRM_ANNOUNCEMENT_MSG = "CONFIRM_ANNOUNCEMENT_MSG",
  DELETE_CONFIRMATION = "DELETE_CONFIRMATION",
  POST_CONFIRMATION = "POST_CONFIRMATION",
  ENROLL_LEGEND = "ENROLL_LEGEND",
  DELETE_COURSE_WARNING = "DELETE_COURSE_WARNING",
  DELETE_ANNOUNCEMENT_WARNING = "DELETE_ANNOUNCEMENT_WARNING",
  DUEDATE = "DUEDATE",
  STARTDATE = "STARTDATE",
  MATH = "MATH",
  LAW = "LAW",
  GEOGRAPHY = "GEOGRAPHY",
  SCIENCE_TECH = "SCIENCE_TECH",
  ECONOMICS = "ECONOMICS",
  HISTORY = "HISTORY",
  ENGLISH_LITERATURE = "ENGLISH_LITERATURE",
  BUSINESS = "BUSINESS",
  ART_DESIGN = "ART_DESIGN",
  ACCOUNTANCE_FINANCE = "ACCOUNTANCE_FINANCE",
  MOVE_TO_TRASH = "MOVE_TO_TRASH",
  DELETE_NOW = "DELETE_NOW",
  DELETE_THIS_NOW = "DELETE_THIS_NOW",
  DELETE_UNRECOVERABLE = "DELETE_UNRECOVERABLE",
  DELETE_TRASHABLE = "DELETE_TRASHABLE",
  LOSE_INFORMATION = "LOSE_INFORMATION",
  SENT_BY = "SENT_BY",
  LOST_DATA_WARNING = "LOST_DATA_WARNING",
  STUDENTS_ASSIGNMENT_WARNING = "STUDENTS_ASSIGNMENT_WARNING",
  UNPUBLISH = "UNPUBLISH",
  STUDENTS_ASSIGNMENT_WARNING_2 = "STUDENTS_ASSIGNMENT_WARNING_2",
  CLOSE_DROPBOX = "CLOSE_DROPBOX",
  SUCCESSFULLY_CREATED = "SUCCESSFULLY_CREATED",
  UNPUBLISH_QUESTION = "UNPUBLISH_QUESTION",
  CONTINUE = "CONTINUE",
  QUIZ = "QUIZ",
  ESSAY = "ESSAY",
  PRESENTATION = "PRESENTATION",
  REPORTS = "REPORTS",
  PUBLISH_DATE = "PUBLISH_DATE",
  ASSIGNMENT_TITLE = "ASSIGNMENT_TITLE",
  ASSIGNMENT_DESCRIPTION = "ASSIGNMENT_DESCRIPTION",
  CLICK_UPLOAD = "CLICK_UPLOAD",
  RECOMMENDED = "RECOMMENDED",
  FILES_LIMIT = "FILES_LIMIT",
  FILES_WARNING = "FILES_WARNING",
  UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR",
  CLEAR = "CLEAR",
  FILE_DESCRIPTION = "FILE_DESCRIPTION",
  EDITING = "EDITING",
  REMOVE = "REMOVE",
  EDIT = "EDIT",
  HOW_WILL_YOU_USE_ICONNECTED = "HOW_WILL_YOU_USE_ICONNECTED",
  HERE = "HERE",
  NO_STUDENTS_ASSIGNMENT = "NO_STUDENTS_ASSIGNMENT",
  ASSIGNMENT_STUDENT1 = "ASSIGNMENT_STUDENT1",
  ASSIGNMENT_STUDENT2 = "ASSIGNMENT_STUDENT2",
  ASSIGNMENT_STUDENT3 = "ASSIGNMENT_STUDENT3",
  FILES = "FILES",
  UPLOAD_ASSIGNMENT_FILES = "UPLOAD_ASSIGNMENT_FILES",
  UNPUBLISH_ASSIGNMENT = "UNPUBLISH_ASSIGNMENT",
  DROPBOX_STATUS = "DROPBOX_STATUS",
  DROPBOX_SETTINGS = "DROPBOX_SETTINGS",
  ARCHIVED_COURSES = "ARCHIVED_COURSES",
  FILTER = "FILTER",
  ID = "ID",
  ENROLLED = "ENROLLED",
  START_END_DATE = "START_END_DATE",
  END_DATE = "END_DATE",
  CREATE_COURSE = "CREATE_COURSE",
  CREATE_COURSE2 = "CREATE_COURSE2",
  ADD_COURSE_BUTTON = "ADD_COURSE_BUTTON",
  NO_ARCHIVED = "NO_ARCHIVED",
  EDIT_ACCOUNT = "EDIT_ACCOUNT",
  CONTACT_EMAIL = "CONTACT_EMAIL",
  YEARS_OF_EXPERIENCE = "YEARS_OF_EXPERIENCE",
  VERIFIED = "VERIFIED",
  EXPERIENCE = "EXPERIENCE",
  UPLOAD_PROFILE_PICTURE = "UPLOAD_PROFILE_PICTURE",
  DISPLAY_PUBLICLY = "DISPLAY_PUBLICLY",
  COUNTRY_DISPLAYED = "COUNTRY_DISPLAYED",
  LANGUAGES_SPOKEN = "LANGUAGES_SPOKEN",
  BIOGRAPHY = "BIOGRAPHY",
  ADD_EXPERIENCE = "ADD_EXPERIENCE",
  EMPTY_BIO_1 = "EMPTY_BIO_1",
  EMPTY_BIO_2 = "EMPTY_BIO_2",
  EMPTY_EXPERIENCES_1 = "EMPTY_EXPERIENCES_1",
  EMPTY_EXPERIENCES_2 = "EMPTY_EXPERIENCES_2",
  COURSES_INSTRUCTED = "COURSES_INSTRUCTED",
  VIEW = "VIEW",
  RESOURCES_UPLOADED = "RESOURCES_UPLOADED",
  FORUM_POSTS = "FORUM_POSTS",
  COMMENT_REPLIES = "COMMENT_REPLIES",
  SCHOOL = "SCHOOL",
  FIELD_OF_STUDY = "FIELD_OF_STUDY",
  DEGREE = "DEGREE",
  LOCATION = "LOCATION",
  DESCRIPTION_EXPERIENCE = "DESCRIPTION_EXPERIENCE",
  OPTIONAL = "OPTIONAL",
  SAVE = "SAVE",
  POSITION_TITLE = "POSITION_TITLE",
  COMPANY = "COMPANY",
  CURRENT_POSITION = "CURRENT_POSITION",
  CERTIFICATES_TITLES_NAME = "CERTIFICATES_TITLES_NAME",
  ISSUING_AUTHORITY = "ISSUING_AUTHORITY",
  CREDENTIAL_LICENSE = "CREDENTIAL_LICENSE",
  NEVER_EXPIRES = "NEVER_EXPIRES",
  AT = "AT",
  RESOURCE_HAS_BEEN_UPDATED = "RESOURCE_HAS_BEEN_UPDATED",
  REGISTERED_LEARNERS = "REGISTERED_LEARNERS",
  UPLOAD_EXPERIENCE_IMAGE = "UPLOAD_EXPERIENCE_IMAGE",
  RECOMMENDED_FILE_EXPERIENCE = "RECOMMENDED_FILE_EXPERIENCE",
  ADD_EDUCATION = "ADD_EDUCATION",
  ADD_CERTIFICATES = "ADD_CERTIFICATES",
  SUPPORT_US = "SUPPORT_US",
  DONATION_MESSAGE = "DONATION_MESSAGE",
  DONATE_THROUGH = "DONATE_THROUGH",
  DONATE_CRYPTO = "DONATE_CRYPTO",
  THANK_YOU_MESSAGE = "THANK_YOU_MESSAGE",
  DONATIONS_OUTSIDE_CANADA = "DONATIONS_OUTSIDE_CANADA",
  DONATIONS_WITH_CRYPTO = "DONATIONS_WITH_CRYPTO",
  DONATIONS_WITHIN_CANADA = "DONATIONS_WITHIN_CANADA",
  RESET = "RESET",
  IRREVERSIBLE = "IRREVERSIBLE",
  INSIGHTS = "INSIGHTS",
  COMPLETED = "COMPLETED",
  COURSE_THEME = "COURSE_THEME",
  ASSIGNED = "ASSIGNED",
  THIS_WEEK = "THIS_WEEK",
  ON_TIME_SUBMISSIONS = "ON_TIME_SUBMISSIONS",
  ALL_TIME = "ALL_TIME",
  ABOUT = "ABOUT",
  ACTIVITY = "ACTIVITY",
  PHONE_NUMBER_PROFILE = "PHONE_NUMBER_PROFILE",
  LANGUAGES = "LANGUAGES",
  ACTIVE_HOURS = "ACTIVE_HOURS",
  NO_COURSES_ENROLLED = "NO_COURSES_ENROLLED",
  NO_FORUM_POSTS = "NO_FORUM_POSTS",
  MY_ACCOUNT = "MY_ACCOUNT",
  PROFILE_PICTURE = "PROFILE_PICTURE",
  CONTACT_EMAIL_PROFILE = "CONTACT_EMAIL_PROFILE",
  HEADLINER = "HEADLINER",
  FILE_SIZE_TYPE_NOT_SUPPORTED = "FILE_SIZE_TYPE_NOT_SUPPORTED",
  EXPERIENCE_IMAGE_SUCCESS = "EXPERIENCE_IMAGE_SUCCESS",
  UPDATE_EDUCATION = "UPDATE_EDUCATION",
  UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE",
  UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE",
  UPDATE = "UPDATE",
  DELETE_EXPERIENCE = "DELETE_EXPERIENCE",
  ADDED_EXPERIENCE_SUCCESSFULLY = "ADDED_EXPERIENCE_SUCCESSFULLY",
  EXPERIENCES = "EXPERIENCES",
  SUCCESSFULLY_SAVED = "SUCCESSFULLY_SAVED",
  CREATE_FIRST_COURSE1 = "CREATE_FIRST_COURSE1",
  CREATE_FIRST_COURSE2 = "CREATE_FIRST_COURSE2",
  CREATE_FIRST_RESPOURCE1 = "CREATE_FIRST_RESPOURCE1",
  CREATE_FIRST_RESPOURCE2 = "CREATE_FIRST_RESPOURCE2",
  CREATE_FIRST_RESPOURCE3 = "CREATE_FIRST_RESPOURCE3",
  SEND_REMINDER = "SEND_REMINDER",
  FILE_TYPES_LIST = "FILE_TYPES_LIST",
  PUBLICATION_SHAREDBY = "PUBLICATION_SHAREDBY",
  EDIT_COMMENT = "EDIT_COMMENT",
  EDIT_THREAD = "EDIT_THREAD",
  ORGANIZATION = "ORGANIZATION",
  CREATE_ORAGANIZATION = "CREATE_ORAGANIZATION",
  STUDENT = "STUDENT",
  GRADE_AND_COMMENT = "GRADE_AND_COMMENT",
  CLASS_LIST = "CLASS_LIST",
  ONGOING_COURSE = "ONGOING_COURSE",
  INSTITUTIONS = "INSTITUTIONS",
  REMOVE_STUDENT = "REMOVE_STUDENT",
  NO_ENROLLED_STUDENTS_3 = "NO_ENROLLED_STUDENTS_3",
  NO_ENROLLED_STUDENTS_4 = "NO_ENROLLED_STUDENTS_4",
  NEXT = "NEXT",
  PREV = "PREV",
  SERACH_STUDENTS = "SERACH_STUDENTS",
  PROFILE = "PROFILE",
  PAUSED = "PAUSED",
  CLOSED = "CLOSED",
  THANK_YOU_INTEREST = "THANK_YOU_INTEREST",
  JOIN_WAITLIST_MESSAGE = "JOIN_WAITLIST_MESSAGE",
  EMAIL_SUBMITTED = "EMAIL_SUBMITTED",
  REGULAR_UPDATE = "REGULAR_UPDATE",
  DASHBOARD_VERIFY_BANNER = "DASHBOARD_VERIFY_BANNER",
  ICONNECTED_GATEWAY = "ICONNECTED_GATEWAY",
  FUTURE_EDUCATION = "FUTURE_EDUCATION",
  WEB_PROVIDE_EDUCATOR = "WEB_PROVIDE_EDUCATOR",
  DESIGNED_FOR_ALL = "DESIGNED_FOR_ALL",
  ICONNECTED_GOAL = "ICONNECTED_GOAL",
  APPROACH_USE_NATURAL_LANGUAGE = "APPROACH_USE_NATURAL_LANGUAGE",
  TRANSFORM_EDUCATION = "TRANSFORM_EDUCATION",
  EVERY_CORNER_CONNECTED = "EVERY_CORNER_CONNECTED",
  LOW_BANDWIDTH = "LOW_BANDWIDTH",
  BARRIER = "BARRIER",
  FREE_REMOTE_EDUCATION = "FREE_REMOTE_EDUCATION",
  ANYWHERE_ANYTIME = "ANYWHERE_ANYTIME",
  CONTACT_US = "CONTACT_US",
  REMOVE_COURSE = "REMOVE_COURSE",
  EDIT_ASSIGNMENT = "EDIT_ASSIGNMENT",
  REMOVE_ASSIGNMENT = "REMOVE_ASSIGNMENT",
  DELETE_STUDENT = "DELETE_STUDENT",
  COPY_COURSE_CODE = "COPY_COURSE_CODE",
  SUCCESS_STORY_FIRST_TITLE = "SUCCESS_STORY_FIRST_TITLE",
  SUCCESS_STORY_SECOND_TITLE = "SUCCESS_STORY_SECOND_TITLE",
  SUCCESS_STORY_1_BOLD = "SUCCESS_STORY_1_BOLD",
  SUCCESS_STORY_1 = "SUCCESS_STORY_1",
  SUCCESS_STORY_2 = "SUCCESS_STORY_2",
  SUCCESS_STORY_2_BOLD = "SUCCESS_STORY_2_BOLD",
  SUCCESS_STORY_3 = "SUCCESS_STORY_3",
  SEARCH_PROGRAMS = "SEARCH_PROGRAMS",
  EDIT_POST = "EDIT_POST",
  REMOVE_POST = "REMOVE_POST",
  CATEGORY = "CATEGORY",
  DESCRIPTION_HEADING = "DESCRIPTION_HEADING",
  SAVE_POST = "SAVE_POST",
  UNDO = "UNDO",
  MANAGE_STUDENT = "MANAGE_STUDENT",
  MANAGING_STUDENT = "MANAGING_STUDENT",
  STUDENT_NAME = "STUDENT_NAME",
  ADD_A_NOTE = "ADD_A_NOTE",
  ENROLLED_COURSES_STUDENT = "ENROLLED_COURSES_STUDENT",
  NO_STUDENT_NOTES = "NO_STUDENT_NOTES",
  NOTES_ON = "NOTES_ON",
  BEEN_UPDATED = "BEEN_UPDATED",
  STUDENT_NOTES = "STUDENT_NOTES",
  HOLISTIC_LEARNING = "HOLISTIC_LEARNING",
  MENTORS_TEXT = "MENTORS_TEXT",
  SUPER_ACCESSIBLE_LEARNING = "SUPER_ACCESSIBLE_LEARNING",
  SUPER_ACCESSIBLE_LEARNING_TEXT = "SUPER_ACCESSIBLE_LEARNING_TEXT",
  IMPROVE_COURSE_MANAGEMENT = "IMPROVE_COURSE_MANAGEMENT",
  IMPROVE_COURSE_MANAGEMENT_TEXT = "IMPROVE_COURSE_MANAGEMENT_TEXT",
  ADVANCE_CAREER_GOAL = "ADVANCE_CAREER_GOAL",
  ADVANCE_CAREER_GOAL_TEXT = "ADVANCE_CAREER_GOAL_TEXT",
  JOIN_WAITLIST = "JOIN_WAITLIST",
  RESOURCE_OPTIONAL = "RESOURCE_OPTIONAL",
  RESOURCE_DESCRIPTION = "RESOURCE_DESCRIPTION",
  FILE_UPLOAD = "FILE_UPLOAD",
  UPLOAD = "UPLOAD",
  WAIT_PROFILE_UPDATING = "WAIT_PROFILE_UPDATING",
  PROFILE_UPDATED = "PROFILE_UPDATED",
  PLACEHOLDER = "PLACEHOLDER",
  SOCIAL_MEDIA_PROFILE_URL = "SOCIAL_MEDIA_PROFILE_URL",
}
