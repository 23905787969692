import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { SPEAKABLE_LANGUAGE } from "@i18n";
import { TranslateService } from "@ngx-translate/core";
import { STORAGE } from "../constants";
import { RawTeacher, Teacher } from "../shared/types";
import { FirebaseService } from "./firebase.service";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  theme: "light" | "dark" | "system" = "system";
  isSidebarOpen = false;
  isHeader = true;
  isSidebarVisible = true;
  isDashboardPage = true;
  public teacher: Teacher;

  constructor(
    private auth: AngularFireAuth,
    private translate: TranslateService,
    private FS: FirebaseService,
  ) {
    this.theme =
      (localStorage.getItem(STORAGE.theme) as typeof this.theme) ?? this.theme;
    this.translate.use(
      localStorage.getItem(STORAGE.language) ?? SPEAKABLE_LANGUAGE.en,
    );

    this.refreshTheme();

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => this.refreshTheme());

    this.initialize();
  }

  public parseRawTeacher(id: string, teacher: RawTeacher): Teacher {
    return {
      id,
      teacherCode: teacher?.hashid ?? "",
      email: teacher?.nombre ?? "",
    };
  }

  async initialize() {
    this.auth.onAuthStateChanged(async (user) => {
      if (user && !this.teacher) {
        const rawTeacher = await this.FS.getTeacher();

        this.teacher = this.parseRawTeacher(rawTeacher.id, rawTeacher.data());
      }

      // if (user) {
      //   if (this.teacherCode == undefined) {
      //     this.teacherCode = await this.FS.get_teacher_code();
      //   }
      //   this.loadCourses();
      // } else {
      //   this.teacherCode = undefined;
      // }
    });
  }

  capitalize(str: string) {
    return str.length > 0
      ? `${str.charAt(0).toUpperCase()}${str.slice(1)}`
      : str;
  }

  refreshTheme() {
    if (
      this.theme === "dark" ||
      (this.theme === "system" &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }

  setTheme(newTheme: typeof this.theme) {
    this.theme = newTheme;
    localStorage.setItem(STORAGE.theme, this.theme);
    this.refreshTheme();
  }

  setLanguage(language: SPEAKABLE_LANGUAGE) {
    this.translate.use(language);
    localStorage.setItem(STORAGE.language, language);
  }

  getLanguage(): "de" | "en" | "es" | "fr" | undefined {
    const language = localStorage.getItem(STORAGE.language);

    if (
      language === "de" ||
      language === "en" ||
      language === "es" ||
      language === "fr"
    ) {
      return language;
    }
  }
}
