import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-status-chip",
  templateUrl: "./status-chip.component.html",
})
export class StatusChipComponent implements OnInit {
  // TODO: move to global type
  @Input() status:
    | "draft"
    | "published"
    | "submitted"
    | "unsubmitted"
    | "deleted"
    | "open"
    | "paused"
    | "closed"
    | "late" = "draft";

  ngOnInit() {}
}
