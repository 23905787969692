import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ant-menu',
  templateUrl: './ant-menu.component.html',
})
export class AntMenuComponent {
  @Input() label: string;
  @Input() icon: any;
  @Input() color: "black" | "red" = "black";

  baseStyle = "text-sm";
  iconStyle = "w-5 h-5";

  get labelStyle() {
      switch (this.color) {
        case "black":
          return `${this.baseStyle} text-black`;
        case "red":
          return `${this.baseStyle} text-red-deep`;
      }
    }

    get colorStyle() {
      switch (this.color) {
        case "black":
          return `${this.iconStyle} text-black`;
        case "red":
          return `${this.iconStyle} text-red-deep`;
      }
    }
}
