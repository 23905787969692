import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
})
export class DropdownComponent {
    @Input() formControl: FormControl;
    @Input() options: { value: string, label: string }[];
    @Input() placeholder: string;
    @Input() customClass: string;
}
