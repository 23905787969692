export const GUPSHUP_CONFIGURATION = {
    PHONE: "16474968529",
    APIKEY: "wq16ai0ymsj6nxaw0ujr2b6gu75h2nzo" ,
    BASIC_ENDPOINT:  "https://api.gupshup.io/sm/api/v1/msg",
    TEMPLATE_ENDPOINT: "https://api.gupshup.io/wa/api/v1/template/msg",
    OPT_ENDPOINT: "http://api.gupshup.io/sm/api/v1/app/opt/",
    REPO_NAME: "iConnectedv2",
    TEMPLATE_IDS: {
        SUBMISSION_REMINDER: {
            ENGLISH: "cefc5c8d-a973-4811-b3e6-1d314ae0b674",
            SPANISH: "a4748b19-c7d9-403e-9cf0-19eadf46c867"
        },
        VIDEO: {
            ENGLISH: "289396e1-5468-4ccd-9212-599d66869045", 
            SPANISH: "79593970-246a-4370-84e0-868dd3caed9b"
        },
        FILE: {
            //ENGLISH: "47b315ae-233d-46c5-aeeb-c9d2f09b4941",
            ENGLISH: "9b803d78-5852-45e5-8ec2-7f65d5d51c81",
            SPANISH: "688102de-d684-45cd-ac50-0d270bc27ffd"
        },
        IMAGE: {
            ENGLISH: "dbdda6e4-6d15-4bcc-8e9b-07e8d850144c",
            SPANISH: "010499ed-286b-49df-92cc-471085347875"
        },
        OTHER: {
            ENGLISH: "5305fa5a-4a39-41b3-91d0-739255ddb316",
            SPANISH: "6f480c31-6294-482e-a2c9-48afb6c9ac54"
        },
        COMMENTS: {
            ENGLISH: "2c9e463a-04b4-48a9-a277-16c039549b57",
            SPANISH: "7cc39928-6b70-48b2-9c5e-619adb8e473f"
        },
        ANNOUNCEMENTS: {
            ENGLISH: "db1a9890-8b6d-4787-a5d0-fab5d6747057",
            SPANISH: "8f84004f-4938-4957-a657-2c3e5ed0d15e"
        },
        ANNOUNCEMENTS_WITH_ATTACHMENT: {
            FILE: {
                ENGLISH: "935d884f-2c82-4bf6-adc2-96e09ee1ee98",
                SPANISH: "4368bba4-c047-4fd8-90a8-1af26b207345"    
            },
            IMAGE: {
                ENGLISH: "285511be-83f1-451c-b475-d4686668c26c",
                SPANISH: "370dadc5-a87f-4c2c-9279-c323cb041361"    
            },
            VIDEO: {
                ENGLISH: "519e2caf-6786-4c91-9dce-eda948a0c0be",
                SPANISH: "c095fa85-17ac-4d03-aa40-47a577e64b77"    
            }
        },
        GRADES: {
            ENGLISH: "0b55e401-e553-49b7-95e4-d2f92a9da847",
            SPANISH: "6a762378-ba23-4267-a9ea-ab3a783f7dd0"
        }
    },
    IMAGE_EXTENSIONS : ["JPG", "PNG", "JPEG"],
    FILE_EXTENSIONS : ["PDF", "DOCX", "ZIP", "PPTX", "XLSX", "DOC"],
    VIDEO_EXTENSIONS : ["MP4", "AVI", "WEBP", "MPEG4"]
};
  