export const MESSENGER_CONFIG = {
    MESSENGER_TOKEN:
        "EAAJp4IZCgZAg4BO9DxJc0KLlBgv535pCwlc8e4LxVvueUInkvboVgbZAaEl8x8B4DMc36C4viCqHuQVOPwUztCmlyEZAjBiaWCA8SO9wcbgG08KlnIZBGNo0iyeJy9KEcWyipUWHb0zhUwd4O3kJytxdYBHkqkWmd8MLGIVwbtbZC5K2TUkiKG1OSwPW2eLwZDZD",
    MESSENGER_PAGE_ID:
        "102912579450412",
    MESSENGER_MESSAGE_PAGE:
        "https://graph.facebook.com/v16.0/me/messages?access_token=",
    IMAGE_EXTENSIONS : ["JPG", "PNG", "JPEG"],
    FILE_EXTENSIONS : ["PDF", "DOCX", "ZIP", "PPTX", "XLSX", "DOC"],
    VIDEO_EXTENSIONS : ["MP4", "AVI", "WEBP", "MPEG4"]
};
