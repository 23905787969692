import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { TRANSLATION } from "@i18n";
import { FirebaseService } from "src/app/services/firebase.service";
import { UserData } from "src/app/shared/user-data";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @Output() openSignupModalAction = new EventEmitter<string>();
  @Output() closeAuthModalAction = new EventEmitter<string>();

  email: string = "";
  password: string = "";
  showPassword: boolean = false;
  btnLoader: boolean = false;

  emailErr: string = "";
  passwordErr: string = "";
  responseErr: string = "";

  userForgotPassword: boolean = false;

  pattern = /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/;
  isValidEmail(e: string) {
    return new RegExp(this.pattern).test(e);
  }

  constructor(
    private FS: FirebaseService,
    public userData: UserData,
    private router: Router,
    private translate: TranslateService,
  ) {}

  ngOnInit() {}

  openSignupodalAction() {
    this.openSignupModalAction.emit();
  }

  passwordToggle() {
    this.showPassword = !this.showPassword;
  }

  handleChange(type: string) {
    this.responseErr = "";
    if (type == "email") {
      this.emailErr = "";
    }
    if (type == "password") {
      this.passwordErr = "";
    }
  }

  handleValidate() {
    let validate = true;
    if (this.email == "") {
      this.emailErr = "Email is required";
      validate = false;
    } else if (!this.isValidEmail(this.email)) {
      this.emailErr = "Email is not valid";
      validate = false;
    }

    if (this.password == "") {
      this.passwordErr = "Password is required";
      validate = false;
    }

    return validate;
  }

  async onSubmit() {
    if (this.handleValidate()) {
      this.btnLoader = true;
      try {
        const user = await this.FS.login(this.email, this.password);
        this.btnLoader = false;
        if (user) {
          // const isVerified = this.FS.isEmailVerified(user);
          localStorage.setItem("userType","1");
          // localStorage.setItem("userDocId", "loxGVfjfEcnYQbFAKwUc")
          // localStorage.setItem("userDocId", "Ej1Orzu0XQa8XESXpn5q")
          this.userData.login(this.email, this.FS.uid);

          this.closeAuthModalAction.emit();
          await this.router.navigate(["dashboard"]);
        }
      } catch (error) {
        this.btnLoader = false;
        switch (error.message) {
          case "The password is invalid or the user does not have a password.": {
            this.responseErr = this.translate.instant(
              TRANSLATION.INVALID_PASSWORD,
            );
            break;
          }
          case "The email address is badly formatted.": {
            this.responseErr = this.translate.instant(
              TRANSLATION.INVALID_EMAIL,
            );
            break;
          }
          case "There is no user record corresponding to this identifier. The user may have been deleted.": {
            this.responseErr = this.translate.instant(
              TRANSLATION.EMAIL_NOT_FOUND,
            );

            break;
          }

          default: {
            // this.responseErr = error.message;
            this.responseErr = this.translate.instant(
              TRANSLATION.INVALID_EMAIL,
            );
            break;
          }
        }
      }
    }
  }
}
