import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "ic-button",
  templateUrl: "./button.component.html",
})
export class ButtonComponent {
  @Output() click: EventEmitter<void> = new EventEmitter<void>();
  @Input() buttonType:
    | "primary"
    | "secondary"
    | "tertiary"
    | "text-only"
    | "checkboxBase"
    | "checkboxActive"
    | "radioBase"
    | "radioActive"
    | "toTop"
    | "switchToggle"
    | "switchToggleOn" = "primary";
  @Input() buttonClass: string;
  @Input() danger: boolean = false;
  @Input() disabled: boolean = false;

  baseStyles =
    "px-4 py-2 text-base font-bold transition duration-75 ease-out rounded disabled:cursor-not-allowed border-2 flex items-center gap-x-2";

  primary =
    "text-white bg-green hover:bg-deep-green border-green disabled:bg-off-white disabled:text-grey-light-1";

  secondary = () =>
    `${
      this.danger
        ? "text-red-warning border-red-warning hover:bg-[#FEDDCB] hover:shadow-lg focus:bg-[#FEDDCB] disabled:text-grey-light-1"
        : "text-green border-green hover:bg-[#DEE8D6] hover:shadow-lg focus:bg-[#DEE8D6] disabled:text-grey-light-1"
    }`;

  tertiary =
    "text-black bg-white border-[#ECECEC] hover:bg-[#ECECEC] hover:shadow-lg  focus:bg-[#ECECEC] disabled:bg-off-white disabled:text-grey-light-1";

  textOnly =
    "text-black border-transparent hover:text-green focus:text-deep-green disabled:text-grey-light-1";

  checkboxBase = "px-4 py-2 text-base border-2 border-[#ECECEC] bg-[#ECECEC]";

  checkboxActive =
    " px-4 py-2 text-base text-green border-2 border-[#ECECEC] bg-[#ECECEC]";

  radioBase = "px-4 py-2 text-base border-2 border-[#ECECEC]";

  radioActive = "px-4 py-2 text-base border-2 border-[#ECECEC] text-cyan-700	";

  toTop =
    "rounded-full bg-green p-3 leading-tight shadow-md transition duration-150 ease-in-out hover:bg-deep-green hover:shadow-lg focus:bg-deep-green focus:shadow-lg focus:outline-none focus:ring-0 active:bg-deep-green active:shadow-lg";

  switchToggleBase = "px-4 py-2 text-base";

  switchToggleOn = "text-green accent-green";
  get styles() {
    switch (this.buttonType) {
      case "primary":
        return `${this.baseStyles} ${this.primary} ${this.buttonClass}`;
      case "secondary":
        return `${this.baseStyles} ${this.secondary()} ${this.buttonClass}`;
      case "tertiary":
        return `${this.baseStyles} ${this.tertiary} ${this.buttonClass}`;
      case "text-only":
        return `${this.baseStyles} ${this.textOnly} ${this.buttonClass}`;
      case "checkboxBase":
        return `${this.checkboxBase} ${this.buttonClass}`;
      case "checkboxActive":
        return `${this.checkboxActive} ${this.buttonClass}`;
      case "radioBase":
        return `${this.radioBase} ${this.buttonClass}`;
      case "radioActive":
        return `${this.radioActive} ${this.buttonClass}`;
      case "toTop":
        return `${this.toTop} ${this.buttonClass}`;
      case "switchToggleOn":
        return `${this.switchToggleBase} ${this.switchToggleOn}`;
    }
  }

  public clicked(): void {
    this.click.emit();
  }
}
