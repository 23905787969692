const LOCAL_STORAGE_PREFIX = "storage-";
export const STORAGE = {
  language: `${LOCAL_STORAGE_PREFIX}language`,
  theme: `${LOCAL_STORAGE_PREFIX}theme`,
};
export const VALID_IMAGE_TYPES = ["image/jpeg", "image/png"];
export const VALID_FILE_TYPES = [
  ...VALID_IMAGE_TYPES,
  "video/mp4",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
//maximun file size by byte
export const MAX_FILE_SIZE = 16 * 1000 * 1000;
export const MAX_IMAGE_SIZE = 5 * 1000 * 1000;
