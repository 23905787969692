import { Component, ElementRef, Input, ViewChild } from "@angular/core";

@Component({
  selector: "app-side-panel",
  templateUrl: "./side-panel.component.html",
})
export class SidePanelComponent {
  @ViewChild("dialog") dialog: ElementRef<HTMLDialogElement>;
  @Input() title: string;

  public open(): void {
    this.dialog.nativeElement.showModal();
  }

  public close(): void {
    this.dialog.nativeElement.close();
  }
}
