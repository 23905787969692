import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import axios, { AxiosInstance } from "axios";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { GUPSHUP_CONFIGURATION } from "../shared/gupshup_ids";
import { Student } from "../shared/types";

@Injectable({
  providedIn: "root",
})
export class GupshupService {
  private readonly axios: AxiosInstance;

  endpoint = GUPSHUP_CONFIGURATION.BASIC_ENDPOINT;
  APIKEY = GUPSHUP_CONFIGURATION.APIKEY;
  TEMPLATES = GUPSHUP_CONFIGURATION.TEMPLATE_IDS;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      apikey: GUPSHUP_CONFIGURATION.APIKEY,
    }),
  };

  constructor(private httpClient: HttpClient) {
    let apiKey = this.APIKEY;

    this.axios = axios.create({
      baseURL: GUPSHUP_CONFIGURATION.TEMPLATE_ENDPOINT,
      headers: {
        authorization: apiKey,
        apiKey,
        "content-type": "application/x-www-form-urlencoded",
      },
    });
  }

  sendMessage(phone: string, message: string): Observable<any> {
    var msj_raw =
      "channel=whatsapp&source=" +
      GUPSHUP_CONFIGURATION.PHONE +
      "&destination=" +
      phone +
      "&message=%7B%22type%22:%22text%22,%22text%22:%22" +
      message +
      "%22%7D&src.name=" +
      GUPSHUP_CONFIGURATION.REPO_NAME;

    return this.httpClient
      .post<any>(this.endpoint, msj_raw, this.httpOptions)
      .pipe(catchError(this.handleError<any>("Error occured")));
  }

  async opt_user(user: string, end: "in" | "out") {
    let params = new URLSearchParams({
      user,
    });
    let ax = axios.create({
      baseURL: `${GUPSHUP_CONFIGURATION.OPT_ENDPOINT}`,
      headers: {
        authorization: this.APIKEY,
        apikey: this.APIKEY,
        "content-type": "application/x-www-form-urlencoded",
      },
    });
    await ax
      .post(
        `${GUPSHUP_CONFIGURATION.OPT_ENDPOINT}${end}/${GUPSHUP_CONFIGURATION.REPO_NAME}`,
        params,
      )
      .catch((error) => {
        console.log("error ", error);
      });
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  async send_templated_video_assignment(url: string, data) {
    const parse_url = new URL(url);
    let filename = parse_url.pathname.split("/").pop().split("%2F").pop();
    let template_id = this.TEMPLATES.VIDEO.ENGLISH;

    if (data.language) {
      if (data.language == "es" || data.language == "ESPAÑOL") {
        template_id = this.TEMPLATES.VIDEO.SPANISH;
      }
    }

    await this.axios
      .post(
        GUPSHUP_CONFIGURATION.TEMPLATE_ENDPOINT,
        new URLSearchParams({
          source: GUPSHUP_CONFIGURATION.PHONE,
          destination: data.phone,
          template:
            '{"id": "' +
            template_id +
            '","params": ["' +
            data.name +
            '","' +
            data.course +
            '"]}',
          message:
            '{"type":"video","video":{"link":"' +
            url +
            '", "filename": "' +
            filename +
            '"}}',
        }),
        {
          headers: {
            apikey: this.APIKEY,
          },
        },
      )
      .then((result) => {
        console.log("Template sent to whatsapp");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async send_templated_file_assignment(url: string, data) {
    const parse_url = new URL(url);
    let filename = parse_url.pathname.split("/").pop().split("%2F").pop();
    let template_id = this.TEMPLATES.FILE.ENGLISH;

    if (data.language) {
      if (data.language == "es" || data.language == "ESPAÑOL") {
        template_id = this.TEMPLATES.FILE.SPANISH;
      }
    }

    await this.axios
      .post(
        GUPSHUP_CONFIGURATION.TEMPLATE_ENDPOINT,
        new URLSearchParams({
          source: GUPSHUP_CONFIGURATION.PHONE,
          destination: data.phone,
          template:
            '{"id": "' +
            template_id +
            '","params": ["' +
            data.name +
            '","' +
            data.course +
            '"]}',
          message:
            '{"type":"document","document":{"link":"' +
            url +
            '", "filename": "' +
            filename +
            '"}}',
        }),
        {
          headers: {
            apikey: this.APIKEY,
          },
        },
      )
      .then((result) => {
        console.log("Template sent to whatsapp");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async send_templated_image_assignment(url: string, data) {
    let template_id = this.TEMPLATES.IMAGE.ENGLISH;

    if (data.language) {
      if (data.language == "es" || data.language == "ESPAÑOL") {
        template_id = this.TEMPLATES.IMAGE.SPANISH;
      }
    }

    await this.axios
      .post(
        GUPSHUP_CONFIGURATION.TEMPLATE_ENDPOINT,
        new URLSearchParams({
          source: GUPSHUP_CONFIGURATION.PHONE,
          destination: data.phone,
          template:
            '{"id": "' +
            template_id +
            '","params": ["' +
            data.name +
            '","' +
            data.course +
            '"]}',
          message: '{"type":"image","image":{"link":"' + url + '"}}',
        }),
        {
          headers: {
            apikey: this.APIKEY,
          },
        },
      )
      .then((result) => {
        console.log("Template sent to whatsapp");
      })
      .catch((error) => {
        console.log(error, url);
      });
  }

  async send_templated_comments(
    assignmet_name: string,
    text: string,
    phone: string,
    name: string,
    language: string,
  ) {
    let english_template = this.TEMPLATES.COMMENTS.ENGLISH;
    let spanish_template = this.TEMPLATES.COMMENTS.SPANISH;

    await this.sendTemplatedMessage(
      text,
      [
        {
          destination: phone,
          id: assignmet_name,
          templateId: language == "es" ? spanish_template : english_template,
        },
      ],
      null,
    );
  }

  async send_templated_direct_message(
    student_name: string,
    assignmet_name: string,
    course_name: string,
    extra_message: string,
    phone: string,
    language: string,
  ) {
    let english_template = this.TEMPLATES.SUBMISSION_REMINDER.ENGLISH;
    let spanish_template = this.TEMPLATES.SUBMISSION_REMINDER.SPANISH;

    await this.sendTemplatedMessageWithParams(
      phone,
      [student_name, assignmet_name, course_name, extra_message],
      language == "ESPAÑOL" ? spanish_template : english_template,
    );
  }

  async send_templated_other_files_assignment(url: string, data) {
    const parse_url = new URL(url);
    let filename = parse_url.pathname.split("/").pop().split("%2F").pop();
    let template_id = this.TEMPLATES.OTHER.ENGLISH;

    if (data.language) {
      if (data.language == "es" || data.language == "ESPAÑOL") {
        template_id = this.TEMPLATES.OTHER.SPANISH;
      }
    }

    let filtered_url = url.split("/o/")[1];

    await this.axios
      .post(
        GUPSHUP_CONFIGURATION.TEMPLATE_ENDPOINT,
        new URLSearchParams({
          source: GUPSHUP_CONFIGURATION.PHONE,
          destination: data.phone,
          template:
            '{"id": "' + template_id + '","params": ["' + filtered_url + '"]}',
        }),
        {
          headers: {
            apikey: GUPSHUP_CONFIGURATION.APIKEY,
          },
        },
      )
      .then((result) => {
        console.log("Template sent to whatsapp ", result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async sendTemplatedMessage(
    message: string,
    receivers: Array<{ destination: string; id: string; templateId: string }>,
    file_data?: { url: string; docType: string },
  ) {
    for (let { destination, id, templateId } of receivers) {
      let params = {
        source: GUPSHUP_CONFIGURATION.PHONE,
        destination: destination,
        template: `{"id": "${templateId}", "params": ["${id}", "${message}"]}`,
      };

      if (file_data != null) {
        const parse_url = new URL(file_data.url);
        let filename = parse_url.pathname.split("/").pop();
        params[
          "message"
        ] = `{"type": "${file_data.docType}","${file_data.docType}": {"link": "${file_data.url}", "filename": "${filename}"}}`;
      }

      await this.axios.post(
        GUPSHUP_CONFIGURATION.TEMPLATE_ENDPOINT,
        new URLSearchParams(params),
        {
          headers: {
            apikey: this.APIKEY,
            "cache-control": "no-cache",
            "Cache-Control": "no-cache",
          },
        },
      );
    }
  }

  async sendTemplatedMessageWithParams(
    destination: string,
    variables: Array<string>,
    templateId: string,
  ) {
    let params = {
      source: GUPSHUP_CONFIGURATION.PHONE,
      destination: destination,
      template: `{"id": "${templateId}", "params": ["${variables.join(
        '","',
      )}"]}`,
    };

    await this.axios.post(
      GUPSHUP_CONFIGURATION.TEMPLATE_ENDPOINT,
      new URLSearchParams(params),
      {
        headers: {
          apikey: this.APIKEY,
          "cache-control": "no-cache",
          "Cache-Control": "no-cache",
        },
      },
    );
  }

  async sendAnnouncement(
    message: string,
    students: Array<{ type: "facebook" | "whatsapp"; student: Student }>,
    course_name: string,
    file_url?: string,
  ) {
    let ROOT_TEMPLATE = this.TEMPLATES;
    let file_data = undefined;
    let TEMPLATES;

    if (file_url) {
      let docType = undefined;

      if (
        GUPSHUP_CONFIGURATION.IMAGE_EXTENSIONS.some((extension) => {
          return file_url.toUpperCase().includes(extension);
        })
      ) {
        TEMPLATES = ROOT_TEMPLATE.ANNOUNCEMENTS_WITH_ATTACHMENT.IMAGE;
        docType = "image";
      }

      if (
        GUPSHUP_CONFIGURATION.FILE_EXTENSIONS.some((extension) => {
          return file_url.toUpperCase().includes(extension);
        })
      ) {
        TEMPLATES = ROOT_TEMPLATE.ANNOUNCEMENTS_WITH_ATTACHMENT.FILE;
        docType = "document";
      }

      if (
        GUPSHUP_CONFIGURATION.VIDEO_EXTENSIONS.some((extension) => {
          return file_url.toUpperCase().includes(extension);
        })
      ) {
        TEMPLATES = ROOT_TEMPLATE.ANNOUNCEMENTS_WITH_ATTACHMENT.VIDEO;
        docType = "video";
      }

      file_data = {
        url: file_url,
        docType: docType,
      };
    } else {
      TEMPLATES = ROOT_TEMPLATE.ANNOUNCEMENTS;
    }

    let englishTemplate = TEMPLATES.ENGLISH;
    let spanishTemplate = TEMPLATES.SPANISH;

    await this.sendTemplatedMessage(
      message,
      students
        .filter((s) => s.type === "whatsapp") // TODO: remove this once messenger is implemented
        .map(({ student }) => ({
          destination: student.phone,
          id: course_name,
          templateId:
            student.language == "ESPAÑOL" ? spanishTemplate : englishTemplate,
        })),
      file_data,
    );
  }

  async sendGradeMessage(
    message: string,
    code_id: string,
    students: Array<{ type: "facebook" | "whatsapp"; student: Student }>,
  ) {
    let englishTemplate = this.TEMPLATES.GRADES.ENGLISH;
    let spanishTemplate = this.TEMPLATES.GRADES.SPANISH;

    await this.sendTemplatedMessage(
      message,
      students
        .filter((s) => s.type === "whatsapp") // TODO: remove this once messenger is implemented
        .map(({ student }) => ({
          destination: student.phone,
          id: code_id,
          templateId:
            student.language && student.language === "ESPAÑOL"
              ? spanishTemplate
              : englishTemplate,
        })),
    );
  }

  async send_image(url: string, phone: string, name: string) {
    await this.axios
      .post(
        GUPSHUP_CONFIGURATION.BASIC_ENDPOINT,
        new URLSearchParams({
          channel: "whatsapp",
          source: GUPSHUP_CONFIGURATION.PHONE,
          destination: phone,
          "src.name": GUPSHUP_CONFIGURATION.REPO_NAME,
          message:
            '{"type":"image",' +
            '"originalUrl":"' +
            url +
            '",' +
            '"previewUrl":"' +
            url +
            '",' +
            '"caption": "You have received an assignment."}',
        }),
        {
          headers: {
            apikey: this.APIKEY,
          },
        },
      )
      .then((result) => console.log(result))
      .catch((err) => console.log(err));
  }

  async send_file(url: string, phone: string, name: string) {
    var extension = "pdf";
    var _type = "file";
    if (url.includes("pdf")) {
      extension = "pdf";
    }
    if (url.includes("mp4") || url.includes("webm")) {
      extension = "mp4";
      _type = "video";
    }
    if (url.includes("docx")) {
      extension = "docx";
    }

    const parse_url = new URL(url);
    let filename = parse_url.pathname.split("/").pop();

    const response = await this.axios
      .post(
        GUPSHUP_CONFIGURATION.BASIC_ENDPOINT,
        new URLSearchParams({
          channel: "whatsapp",
          source: GUPSHUP_CONFIGURATION.PHONE,
          destination: phone,
          "src.name": GUPSHUP_CONFIGURATION.REPO_NAME,
          message:
            '{"type":"' +
            _type +
            '",' +
            '"url":"' +
            url +
            '",' +
            '"filename":' +
            filename +
            "}",
        }),
        {
          headers: {
            apikey: this.APIKEY,
          },
        },
      )
      .then((err) => console.log(err))
      .catch((err) => console.log(err));
  }

  async send_assignment_selector(url: string, data, media_type: string) {
    if (media_type == "image") {
      await this.send_templated_image_assignment(url, data);
    } else if (media_type == "video") {
      await this.send_templated_video_assignment(url, data);
    } else if (media_type == "file") {
      await this.send_templated_file_assignment(url, data);
    } else {
      await this.send_templated_other_files_assignment(url, data);
    }
  }
}
