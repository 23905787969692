import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {
  AngularFirestore,
  QueryDocumentSnapshot,
} from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Observable } from "rxjs";
import { Final_User } from "../shared/Final_user.interface";
import {
  Institution,
  Organization,
  RawStudent,
  RawTeacher,
  Student,
} from "../shared/types";
import { User } from "../shared/user.interface";

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  public code;
  Collection_Assignments = "tareas";
  Collection_Homeworks = "registro_tareas";
  Collection_Posts = "Posts";
  Collection_Uploads = "Uploads";
  Collection_Codes = "Codigos";
  Collection_Teachers = "profesores";
  Collection_Students = "alumnos";
  Collection_Institutions = "institutions";
  Collection_Users = "users";

  user$: Observable<User>;
  final_user$: Observable<Final_User>;
  username: string;
  uid: string;
  isAdmin: boolean;
  PostList: { id: string; email: any }[];

  constructor(
    private firestore: AngularFirestore,
    public auth: AngularFireAuth,
    private storage: AngularFireStorage,
  ) {}

  async check_registered_email(email: string): Promise<boolean> {
    let answer: boolean = false;
    await this.firestore
      .collection(this.Collection_Users, (ref) =>
        ref.where("email", "==", email),
      )
      .get()
      .forEach((snapshots) => {
        answer = snapshots.docs.length != 0;
      });
    console.log(answer, email);
    return answer;
  }

  // Assignments
  async query_homeworks_by_uid(educator_code: string) {
    return await this.firestore
      .collection(this.Collection_Homeworks, (ref) =>
        ref.where("profesor", "==", educator_code),
      )
      .snapshotChanges();
  }

  delete_post(record_id) {
    return this.firestore.doc(this.Collection_Posts + "/" + record_id).delete();
  }

  //Authentication
  public async updateProfileImage(file: File, username: string) {
    //Force extension to jpg
    let extension = "jpg"; //file.name.split(".").pop();

    const ref = this.storage.ref(
      `profileImage/${username.toString()}/profile.${extension}`,
    );

    let fullpath = await ref.put(file);
    let profile_url = await ref.getDownloadURL().toPromise();

    (await this.auth.currentUser).updateProfile({
      photoURL: profile_url,
    });

    return profile_url.toString();
  }

  public updateProfileName(firstname: string, lastname: string) {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/v8/firebase.User
        user.updateProfile({
          displayName: firstname + " " + lastname,
        });

        await this.firestore.doc(`users/${user.uid}`).update({
          name: firstname,
          lastname: lastname,
        });
      } else {
        // User is signed out
        // ...
      }
    });
  }

  public getProfileImage(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          console.log(
            "in getprofile correct ",
            user.displayName,
            user.photoURL,
          );
          return resolve(user.photoURL);
        } else {
          return reject();
        }
      });
    });
  }

  public getProfile() {
    return new Promise((resolve, reject) => {
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          console.log("in getprofile correct ");
          return resolve(user);
        } else {
          return reject();
        }
      });
    });
  }

  async sendVerificationEmail(): Promise<void> {
    try {
      return (await this.auth.currentUser).sendEmailVerification();
    } catch (error) {
      console.log("Error->", error);
    }
  }

  async registerInstitution(institution: Organization): Promise<string> {
    let doc_ref = await this.firestore
      .collection(this.Collection_Institutions)
      .add(institution);
    return doc_ref.id;
  }

  async registerSubOraganization(institution: Organization): Promise<string> {
    let doc_ref = await this.firestore
      .collection(this.Collection_Institutions)
      .add(institution);
    return doc_ref.id;
  }

  async registerUser(
    password: string,
    profile: Omit<User, "emailVerified" | "uid">,
  ): Promise<void> {
    const { user } = await this.auth.createUserWithEmailAndPassword(
      profile.email,
      password,
    );

    await this.sendVerificationEmail();

    //set Profile Name in Firebase  Auth
    await user.updateProfile({
      displayName: profile.firstname + " " + profile.lastname,
    });

    await this.firestore
      .doc(`users/${user.uid}`)
      .set(
        { ...profile, uid: user.uid, emailVerified: user.emailVerified },
        { merge: true },
      );
  }

  async getUserData() {
    this.uid = (await this.auth.currentUser).uid;
    return (
      await this.firestore.doc(`users/${this.uid}`).get().toPromise()
    ).data();
  }

  async getInstitutionData() {
    this.uid = (await this.auth.currentUser).uid;
    let educator_data = (
      await this.firestore.doc(`users/${this.uid}`).get().toPromise()
    ).data();

    let institution_data = {};
    if (educator_data["isAdmin"]) {
      institution_data = (
        await this.firestore
          .doc(
            `${this.Collection_Institutions}/${educator_data["institution_id"]}`,
          )
          .get()
          .toPromise()
      ).data();
    }

    return institution_data;
  }

  async changePassword(newPassword: string) {
    let user = await this.auth.currentUser;
    if (user) {
      console.log(user, newPassword);
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/v8/firebase.User
      user.updatePassword(newPassword).then(async () => {
        console.log("out after password updated", newPassword);
        await this.logout();
      });
      //console.log(user.providerData[0])
    } else {
      // User is signed out
      // ...
    }
  }

  async login(email: string, password: string): Promise<User> {
    const { user } = await this.auth.signInWithEmailAndPassword(
      email,
      password,
    );

    this.username = (await this.auth.currentUser).email;
    this.uid = (await this.auth.currentUser).uid;

    return user;
  }

  async resetPassword(email: string): Promise<void> {
    return this.auth.sendPasswordResetEmail(email);
  }

  isEmailVerified(user: User): boolean {
    return user.emailVerified;
  }

  async logout(): Promise<void> {
    try {
      await this.auth.signOut();
      this.username = null;
    } catch (error) {
      console.log("Error->", error);
    }
  }

  check_code(code: String) {
    return this.firestore
      .collection(this.Collection_Codes, (ref) =>
        ref.where("codigo", "==", code),
      )
      .snapshotChanges();
  }

  async query_schools() {
    return await this.firestore
      .collection(this.Collection_Codes)
      .get()
      .toPromise();
  }

  async add_school(data) {
    await this.firestore.collection(this.Collection_Codes).add(data);
  }

  async getEmail() {
    return (await this.auth.currentUser).email;
  }

  async getTeacher(): Promise<QueryDocumentSnapshot<RawTeacher> | undefined> {
    this.uid = (await this.auth.currentUser).uid;

    const teacher = await this.firestore
      .collection<RawTeacher>(this.Collection_Teachers, (ref) =>
        ref.where("folder", "==", this.uid),
      )
      .get()
      .toPromise();

    if (!teacher.docs[0]) {
      return undefined;
    }

    return teacher.docs[0];
  }

  async get_teacher_code() {
    this.uid = (await this.auth.currentUser).uid;

    let teacher_uid = await this.firestore
      .collection(this.Collection_Teachers, (ref) =>
        ref.where("folder", "==", this.uid),
      )
      .get()
      .toPromise()
      .then((user) => {
        return user.docs[0].data()["hashid"];
      });

    return teacher_uid;
  }

  async check_profile_image_url(username: string) {
    try {
      const ref = this.storage.ref(`profileImage/${username}`);

      const { items } = await ref.list().toPromise();

      if (items.length > 0) {
        return await items[0].getDownloadURL();
      }

      return `https://api.dicebear.com/6.x/fun-emoji/svg?seed=${username}`;
    } catch {
      return `https://api.dicebear.com/6.x/fun-emoji/svg?seed=${username}`;
    }
  }

  async get_user_code(uid): Promise<any> {
    return await this.firestore.collection("users").doc(uid).get();
  }

  async get_storage_reference(url) {
    return await this.storage.refFromURL(url);
  }

  async get_url_from_ref(rref) {
    return await this.storage.ref(rref).getDownloadURL();
  }

  async delete_student(id) {
    await this.firestore.collection(this.Collection_Students).doc(id).delete();
  }

  parseRawStudent(id: string, student: RawStudent): Student {
    return {
      id,
      name: student.nombre,
      phone: student.phone,
      code: student.codigo,
      isVerified: student.verificado,
      language: student.language ? student.language : "ENGLISH",
      messenger_id: student.messenger_id ? student.messenger_id : undefined,
    };
  }

  async getStudent(id: string): Promise<Student> {
    const rawStudent = await this.firestore
      .collection(this.Collection_Students)
      .doc(id)
      .get()
      .toPromise();

    return this.parseRawStudent(rawStudent.id, rawStudent.data() as RawStudent);
  }

  async getStudents(teacherCode: string): Promise<Student[]> {
    const snapshot = await this.firestore
      .collection(this.Collection_Students, (ref) =>
        ref
          .where("codigo", ">=", teacherCode)
          .where("codigo", "<=", teacherCode + "~"),
      )
      .get()
      .toPromise();

    return snapshot.docs.map((doc) =>
      this.parseRawStudent(doc.id, doc.data() as RawStudent),
    );
  }

  async query_students(course_code: string) {
    let data = [];
    await this.firestore
      .collection(this.Collection_Students, (ref) =>
        ref.where("codes_array", "array-contains", course_code.trim()),
      )
      .get()
      .toPromise()
      .then(async (snapshot) => {
        await snapshot.forEach(async (doc) => {
          var alumno = doc.data();
          alumno["id"] = doc.id;

          data.push(alumno);
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return data;
  }

  //Institution
  public async updateInstitutionProfileImage(
    file: File,
    institution_id: string,
  ) {
    //Force extension to jpg
    let extension = "jpg"; //file.name.split(".").pop();

    const ref = this.storage.ref(
      `institutionImage/${institution_id.toString()}/profile.${extension}`,
    );

    let fullpath = await ref.put(file);
    let url = await ref.getDownloadURL().toPromise();

    await this.firestore
      .collection(this.Collection_Institutions)
      .doc(institution_id)
      .update({
        institutionProfileImage: url,
      });

    return ref;
  }
}
