import { Injectable, OnDestroy } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { arrayRemove } from "@angular/fire/firestore";
import { BehaviorSubject, Subscription } from "rxjs";
import { first, map } from "rxjs/operators";
import {
  Assignment,
  Course,
  RawCourse,
  RawTeacher,
  Teacher,
} from "../shared/types";
import { FirebaseService } from "./firebase.service";
import { GlobalService } from "./global.service";

@Injectable({
  providedIn: "root",
})
export class CourseService implements OnDestroy {
  allCourses$ = new BehaviorSubject<Course[]>([]); // all courses including those in trash
  courses$ = new BehaviorSubject<Course[]>([]);
  coursesSubscription: Subscription;
  teacherCode: string;

  collection = "codigos";
  studentsCollection = "alumnos";
  public loading = true;

  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private FS: FirebaseService,
    private Auth: AngularFireAuth,
    private globalService: GlobalService, // private SS : StudentService
  ) {
    this.initialize();
  }

  ngOnDestroy(): void {
    if (this.coursesSubscription) {
      this.coursesSubscription.unsubscribe();
    }
  }

  async initialize() {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (this.teacherCode == undefined) {
          this.teacherCode = await this.FS.get_teacher_code();
        }
        if (+localStorage.getItem("userType") === 2) {
          await this.loadCourse();
        } else {
          await this.loadCourses();
        }
      } else {
        this.teacherCode = undefined;
      }
    });
  }

  async parseRawCourse(id: string, course: RawCourse): Promise<Course> {
    const teachers: Teacher[] = [];

    if (course.ref) {
      // get referenced courses
      const courseRef = await course.ref.get();
      course = courseRef.data() as RawCourse;

      // get course owner
      const ownerRef = await courseRef.ref.parent.parent.get();

      teachers.push(
        this.globalService.parseRawTeacher(ownerRef.id, ownerRef.data()),
      );

      // get additional teachers
      if (course.teachers) {
        teachers.push(
          ...(await Promise.all(
            course.teachers.map(async (s) => {
              const teacher = await s.get();

              return this.globalService.parseRawTeacher(
                teacher.id,
                teacher.data() as RawTeacher,
              );
            }),
          )),
        );
      }
    } else {
      teachers.push(this.globalService.teacher);
    }

    const student_count = await this.FS.query_students(
      this.teacherCode + "-" + course.codigo,
    );

    const ncourse = {
      documentId: id,
      id: course.codigo,
      name: course.materia,
      status: course.status,
      subject: course.subject ? course.subject : "--",
      student_count: student_count ? student_count.length : -1,
      startDate:
        course.startDate != null
          ? new Date(course.startDate["seconds"] * 1000)
          : new Date(),
      endDate:
        course.endDate != null
          ? new Date(course.endDate["seconds"] * 1000)
          : new Date(),
      lastModified: course.lastModified?.toDate(),
      teachers,
    };

    return ncourse;
  }

  async parseRawCourseForStudent(
    id: string,
    course: RawCourse,
    teacher: Teacher[],
  ): Promise<Course> {
    // console.log(teacherCode);

    const student_count = await this.FS.query_students(
      teacher[0].teacherCode + "-" + course.codigo,
    );

    const ncourse = {
      documentId: id,
      id: course.codigo,
      name: course.materia,
      status: course.status,
      subject: course.subject ? course.subject : "--",
      student_count: student_count ? student_count.length : -1,
      startDate:
        course.startDate != null
          ? new Date(course.startDate["seconds"] * 1000)
          : new Date(),
      endDate:
        course.endDate != null
          ? new Date(course.endDate["seconds"] * 1000)
          : new Date(),
      lastModified: course.lastModified?.toDate(),
      teachers: teacher,
    };

    return ncourse;
  }

  async removeStudent(courseCode: string, studentId: string) {
    console.log("removing ", studentId, "from course", courseCode);

    await this.firestore
      .collection(this.studentsCollection)
      .doc(studentId)
      .update({ codes_array: arrayRemove(courseCode) });
  }

  async loadCourses() {
    this.loading = true;
    //this.teacherCode = await this.FS.get_teacher_code();

    const snapshot = await this.firestore
      .collection("profesores", (ref) =>
        ref.where("hashid", "==", this.teacherCode),
      )
      .get()
      .toPromise();

    const teacher = snapshot.docs[0];

    this.coursesSubscription = this.firestore
      .collection("profesores", (ref) =>
        ref.where("hashid", "==", this.teacherCode),
      )
      .doc(teacher.id)
      .collection(this.collection)
      .snapshotChanges()
      .pipe(
        map((snapshots) =>
          snapshots.map((snapshot) => {
            const id = snapshot.payload.doc.id;
            const course = snapshot.payload.doc.data() as RawCourse;

            return { id, course };
          }),
        ),
      )
      .subscribe(async (courses) => {
        this.courses$.next(
          await Promise.all(
            courses
              .filter(({ course }) => course.status !== "trash")
              .map(
                async ({ id, course }) => await this.parseRawCourse(id, course),
              ),
          ),
        );

        this.allCourses$.next(
          await Promise.all(
            courses.map(async ({ id, course }) => {
              return await this.parseRawCourse(id, course);
            }),
          ),
        );
      });

    this.loading = false;
  }

  async createCourse(course: RawCourse) {
    if (await this.Auth.authState.pipe(first()).toPromise()) {
      const teacherCode = await this.FS.get_teacher_code();

      this.firestore
        .collection("profesores", (ref) =>
          ref.where("hashid", "==", teacherCode),
        )
        .get()
        .toPromise()
        .then(async (snapshot) => {
          if (!snapshot.empty) {
            await this.firestore
              .collection("profesores")
              .doc(snapshot.docs[0].id)
              .collection(this.collection)
              .add(course);
          }
        });
    }
  }

  async updateCourse(course: RawCourse) {
    if (await this.Auth.authState.pipe(first()).toPromise()) {
      const teacherCode = await this.FS.get_teacher_code();

      const teacher = await this.firestore
        .collection("profesores", (ref) =>
          ref.where("hashid", "==", teacherCode),
        )
        .get()
        .toPromise();

      const courses = await this.firestore
        .collection("profesores")
        .doc(teacher.docs[0].id)
        .collection(this.collection, (ref) =>
          ref.where("codigo", "==", course.codigo),
        )
        .get()
        .toPromise();

      await this.firestore
        .collection("profesores")
        .doc(teacher.docs[0].id)
        .collection(this.collection)
        .doc(courses.docs[0].id)
        .set({ lastModified: new Date(), ...course });
    }
  }

  async deleteCourse({ id }: Course, when: "now" | "30-days" = "30-days") {
    if (await this.Auth.authState.pipe(first()).toPromise()) {
      const teacherCode = await this.FS.get_teacher_code();

      const teacher = await this.firestore
        .collection("profesores", (ref) =>
          ref.where("hashid", "==", teacherCode),
        )
        .get()
        .toPromise();

      const courses = await this.firestore
        .collection("profesores")
        .doc(teacher.docs[0].id)
        .collection(this.collection)
        .get()
        .toPromise();

      const course = this.firestore
        .collection("profesores")
        .doc(teacher.docs[0].id)
        .collection(this.collection)
        .doc(courses.docs.find((c) => c.data().codigo === id).id);

      if (when === "now") {
        await course.delete();
      } else {
        await course.update({ status: "trash" });
      }
    }
  }

  async restoreCourse(course: Course) {
    if (await this.Auth.authState.pipe(first()).toPromise()) {
      await this.updateCourse({
        codigo: course.id,
        materia: course.name,
        status: null,
        student_count: course.student_count ? course.student_count : -1,
      });
    }
  }

  async getCourseName(assignment: Assignment): Promise<string> {
    const teacher = await this.firestore
      .collection("profesores", (ref) =>
        ref.where("hashid", "==", assignment.teacherId),
      )
      .get()
      .toPromise();

    const course_name = await this.firestore
      .collection("profesores")
      .doc(teacher.docs[0].id)
      .collection(this.collection, (ref) =>
        ref.where("codigo", "==", assignment.courseId.trim()),
      )
      .get()
      .pipe(
        map(
          (snapshots) =>
            snapshots.docs.map((doc) => {
              const data = doc.data() as RawCourse;
              return data.materia;
            }),
          //return {"a":"b"};
        ),
      )
      .toPromise();

    return course_name[0];
  }

  async getCourses(teacherCode: string): Promise<Course[]> {
    const snapshot = await this.firestore
      .collection("profesores", (ref) => ref.where("hashid", "==", teacherCode))
      .get()
      .toPromise();

    const teacher = snapshot.docs[0];
    const courses = await this.firestore
      .collection("profesores", (ref) => ref.where("hashid", "==", teacherCode))
      .doc(teacher.id)
      .collection(this.collection)
      .get()
      .pipe(
        map((snapshots) =>
          snapshots.docs.map((doc) => {
            const id = doc.id;
            const course = doc.data() as RawCourse;

            return { id, course };
          }),
        ),
      )
      .toPromise();

    return Promise.all(
      courses.map(
        async ({ id, course }) => await this.parseRawCourse(id, course),
      ),
    );
  }

  // async getCourseByCourseCode(teacherCode: string, courseCode : string): Promise<void> {

  //   const snapshot = await this.firestore
  //     .collection("profesores", (ref) => ref.where("hashid", "==", teacherCode))
  //     .get()
  //     .toPromise();

  //   const teacher = {
  //     id: snapshot.docs[0].id,
  //     teacher : snapshot.docs[0].data()
  //   }

  //   const courses = await this.firestore
  //     .collection("profesores", (ref) => ref.where("hashid", "==", teacherCode))
  //     .doc(teacher.id)
  //     .collection(this.collection, (ref) => ref.where("codigo", "==", courseCode))
  //     .get()
  //     .pipe(
  //       map((snapshots) =>
  //         snapshots.docs.map((doc) => {
  //           const id = doc.id;
  //           let course = doc.data() as RawCourse;
  //           return { id, course };
  //         })
  //       ),
  //     )
  //     .subscribe(async (courses) => {
  //       this.courses$.next(
  //         await Promise.all(
  //           courses
  //             .filter(({ course }) => course.status !== "trash" )
  //             .map(async ({ id, course }) =>
  //               await this.parseRawCourse(id, course)
  //             ),
  //         ),
  //       );

  //       this.allCourses$.next(
  //         await Promise.all(
  //           courses.map(async ({ id, course }) => {
  //             return await this.parseRawCourse(id, course);
  //           }),
  //         ),
  //       );
  //     });

  //   // return Promise.all(
  //   //   courses.map(async ({ id, course }) =>
  //   //     await this.parseRawCourseForStudent(id, course,teacher)
  //   //   ),
  //   // );
  // }

  async getCourseByCourseCode(
    teacherCode: string,
    courseCode: string,
  ): Promise<Course[]> {
    const teacher: Teacher[] = [];

    const snapshot = await this.firestore
      .collection("profesores", (ref) => ref.where("hashid", "==", teacherCode))
      .get()
      .toPromise();

    teacher.push(
      this.globalService.parseRawTeacher(
        snapshot.docs[0].id,
        snapshot.docs[0].data() as RawTeacher,
      ),
    );

    const courses = await this.firestore
      .collection("profesores", (ref) => ref.where("hashid", "==", teacherCode))
      .doc(snapshot.docs[0].id)
      .collection(this.collection, (ref) =>
        ref.where("codigo", "==", courseCode),
      )
      .get()
      .pipe(
        map((snapshots) =>
          snapshots.docs.map((doc) => {
            const id = doc.id;
            let course = doc.data() as RawCourse;
            return { documentId: id, ...course };
          }),
        ),
      )
      .toPromise();

    return Promise.all(
      courses.map(
        async (course) =>
          await this.parseRawCourseForStudent(
            course.documentId,
            course,
            teacher,
          ),
      ),
    );
  }

  async loadCourse(): Promise<void> {
    const codes_array = localStorage
      .getItem("studentCourseCodeArray")
      .split(",");

    if (codes_array.length > 0) {
      const accumulatedCourses: Course[] = [];

      await Promise.all(
        codes_array.map(async (id) => {
          const [teacherCode, courseCode] = id.split("-");
          const courses = await this.getCourseByCourseCode(
            teacherCode,
            courseCode,
          );
          accumulatedCourses.push(...courses);
        }),
      );

      // Update the observables with the accumulated courses
      this.courses$.next(accumulatedCourses);
      this.allCourses$.next(accumulatedCourses);
    }
  }
}
