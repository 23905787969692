import { Injectable, OnDestroy } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subscription, first, map } from "rxjs";
import {
  Forum,
  ForumComment,
  RawForum,
  RawForumComment,
} from "../shared/types";
import { FirebaseService } from "./firebase.service";
import { GlobalService } from "./global.service";

@Injectable({
  providedIn: "root",
})
export class ForumService implements OnDestroy {
  allForums$ = new BehaviorSubject<Forum[]>([]); // all forums including those in trash
  forums$ = new BehaviorSubject<Forum[]>([]);
  forumsSubscription: Subscription;
  teacherCode: string;
  collection = "Posts";
  public loading = true;

  constructor(
    private firestore: AngularFirestore,
    private FS: FirebaseService,
    private auth: AngularFireAuth,
    private translate: TranslateService,
    private globalService: GlobalService,
  ) {
    this.initialize();
  }

  ngOnDestroy(): void {
    if (this.forumsSubscription) {
      this.forumsSubscription.unsubscribe();
    }
  }

  async initialize() {
    let user = await this.auth.currentUser;

    if (user) {
      if (this.teacherCode == undefined) {
        this.teacherCode = await this.FS.get_teacher_code();
      }

      this.loadForums();
    } else {
      this.teacherCode = undefined;
    }
  }

  async parseRawForumComment(comment: RawForumComment): Promise<ForumComment> {
    const search_url = await this.FS.check_profile_image_url(comment.Username);

    return {
      username: comment.Username,
      date: comment.Date,
      comment: comment.comment,
      user_profile_image: search_url,
    };
  }

  parseForumComment(comment: ForumComment): RawForumComment {
    return {
      Username: comment.username,
      Date: comment.date,
      comment: comment.comment,
    };
  }

  async parseRawForum(
    id: string,
    rawForum: RawForum,
    user_language: string,
  ): Promise<Forum> {
    const searchUrl = await this.FS.check_profile_image_url(rawForum.Username);

    const comments = await Promise.all(
      rawForum.coments?.map(async (comment) => {
        return await this.parseRawForumComment(comment);
      }),
    );

    const forum = {
      id,
      username: rawForum.Username,
      original_title: rawForum.Post,
      title: rawForum.translated_post?.[user_language] ?? rawForum.Post,
      body: rawForum.translated_body?.[user_language] ?? rawForum.body,
      date: rawForum.Date,
      comments: comments ?? [],
      original_comments: rawForum.original_comments ?? [],
      category: rawForum.category ?? "social",
      status: rawForum.status,
      lastModified: rawForum.lastModified?.toDate() ?? new Date(),
      user_profile_image: searchUrl,
    };

    // if translations are available override comments with translations for displaying
    if (user_language && rawForum.translated_comments) {
      // map not foreach working

      for (let index in rawForum.translated_comments) {
        if (rawForum.translated_comments[index][user_language]) {
          forum.comments[index].comment =
            rawForum.translated_comments[index][user_language];
        }
      }
    }

    return forum;
  }

  async loadForums() {
    this.loading = true;
    //this.translate.getBrowserLang();
    this.forumsSubscription = this.firestore
      .collection(this.collection, (ref) => ref.orderBy("Date", "desc"))
      .snapshotChanges()
      .pipe(
        map((snapshots) =>
          snapshots.map((snapshot) => {
            const id = snapshot.payload.doc.id;
            const forum = snapshot.payload.doc.data() as RawForum;

            return { id, forum };
          }),
        ),
      )
      .subscribe(async (forums) => {
        this.forums$.next(
          await Promise.all(
            forums
              .filter(({ forum }) => forum.status !== "trash")
              .map(async ({ id, forum }) => {
                let user_language = this.globalService.getLanguage();
                return await this.parseRawForum(id, forum, user_language);
              }),
          ),
        );

        this.allForums$.next(
          await Promise.all(
            forums.map(async ({ id, forum }) => {
              let user_language = this.globalService.getLanguage();
              return await this.parseRawForum(id, forum, user_language);
            }),
          ),
        );
      });

    this.loading = false;
  }

  async createForum(forum: RawForum) {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      return this.firestore.collection(this.collection).add(forum);
    }
  }

  async updateForum(id: string, forum: RawForum) {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      await this.firestore.collection(this.collection).doc(id).set(forum);
    }
  }

  async deleteForum({ id }: Forum, when: "now" | "30-days" = "30-days") {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      const forum = this.firestore.collection(this.collection).doc(id);

      if (when === "now") {
        await forum.delete();
      } else {
        await forum.update({ status: "trash" });
      }
    }
  }

  async restoreForum(id: string) {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      const forum = this.firestore.collection(this.collection).doc(id);

      await forum.update({ status: null });
    }
  }

  async createForumComment(forum: Forum, comment: RawForumComment) {
    const rawForum: RawForum = {
      Username: forum.username,
      Post: forum.original_title,
      Date: forum.date,
      coments: forum.comments.map(this.parseForumComment).concat(comment),
      original_comments: forum.original_comments.concat(comment.comment),
      category: forum.category,
      user_profile_image: forum.user_profile_image,
    };

    if (forum.body) {
      rawForum.body = forum.body;
    }

    await this.updateForum(forum.id, rawForum);
  }

  async updateForumThread(id: string, forum: any) {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      await this.firestore.collection(this.collection).doc(id).update(forum);
    }
  }
}
