import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";

@Component({
  selector: "app-scroll-top",
  templateUrl: "./scroll-top.component.html",
})
export class ScrollTopComponent {
  windowScrolled = false;
  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    window.addEventListener("scroll", () => {
      this.windowScrolled = window.pageYOffset > 20;
    });
  }
  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}
