import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FirebaseService } from "../services/firebase.service";

@Injectable({
  providedIn: "root",
})
export class UserData {
  favorites: string[] = [];
  HAS_LOGGED_IN = "hasLoggedIn";
  HAS_SEEN_TUTORIAL = "hasSeenTutorial";
  CODE = "";
  UID = "";
  PostList = [];
  //codigo_maestro = null
  admin = true;
  image_behavior: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(public FS: FirebaseService) {
  }

  hasFavorite(sessionName: string): boolean {
    return this.favorites.indexOf(sessionName) > -1;
  }

  addFavorite(sessionName: string): void {
    this.favorites.push(sessionName);
  }

  removeFavorite(sessionName: string): void {
    const index = this.favorites.indexOf(sessionName);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
  }

  login(username: string, uid: string) {
    localStorage.setItem(this.HAS_LOGGED_IN, "true");
    this.setUsername(username);
    this.setUid(uid);
    //this.getUid();
    //TODO cambiar automaticamente el codigo
    //this.setCodigoMaestro(username);
    return window.dispatchEvent(new CustomEvent("user:login"));
  }

  signup(username: string) {
    localStorage.setItem(this.HAS_LOGGED_IN, "true");

    this.setUsername(username);
    return window.dispatchEvent(new CustomEvent("user:signup"));
  }

  logout() {
    this.FS.logout();
    localStorage.removeItem("uid");
    this.getUid();

    localStorage.removeItem(this.HAS_LOGGED_IN);
    localStorage.removeItem("username");
    localStorage.removeItem("profile_image");
    console.log("DELETED");

    window.dispatchEvent(new CustomEvent("user:logout"));
  }

  setUsername(username: any) {
    localStorage.setItem("username", username.value);
  }

  setUid(uid: any) {
    localStorage.setItem("uid", uid);
  }

  getUsername() {
    return localStorage.getItem("username");
  }

  getUid() {
    return localStorage.getItem("uid");
  }

  isLoggedIn() {
    return Boolean(localStorage.getItem(this.HAS_LOGGED_IN));
  }

  checkHasSeenTutorial() {
    return localStorage.getItem(this.HAS_SEEN_TUTORIAL);
  }

  getuserid() {
    return this.getUid();
  }

  updateProfileImage(new_image) {
    localStorage.setItem("profile_image", new_image);
  }

  getProfileImage(user) {
    const default_image =
      `https://api.dicebear.com/6.x/fun-emoji/svg?seed=${user.email}`;

    return user.photoURL ? user.photoURL : default_image;
  }

  //TODO cambiar automaticamente el codigo d
  /*
  async setCodigoMaestro(codigo: string): Promise<string> {
    var username = await this.getUsername().
    console.log("codigo", codigo, username)
    return await this.FS.get_codigo_hash(username).then(data => {
      console.log("actualizando codigomaestro", data)
      return this.storage.set("codigo_maestro",data)
    })
  }

  async getAndUpdateCodigoMaestro(username): Promise<string> {
    return await this.FS.get_codigo_hash(username).then((data) => {
        return this.storage.set("codigo_maestro",data)
    })
  }
  getCodigoMaestro(): Promise<string> {
    return this.storage.get('codigo_maestro').then((value) => {
      console.log("get codigo", value)
      return value;
    });
  }
  */
}
