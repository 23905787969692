import { Injectable, OnDestroy } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { first, map } from "rxjs/operators";
import {
  Assignment,
  AssignmentSubmission,
  AssignmentSubmissionComment,
  DirectMesage,
  RawAssignment,
  RawAssignmentSubmission,
  Student,
  StudentCommentOnSubmission,
} from "../shared/types";
import { FirebaseService } from "./firebase.service";
import { GupshupService } from "./gupshup.service";
import { MessengerService } from "./messenger.service";

@Injectable({
  providedIn: "root",
})
export class AssignmentService implements OnDestroy {
  allAssignments$: BehaviorSubject<Assignment[]> = new BehaviorSubject([]); // all assignments including those in trash
  assignments$: BehaviorSubject<Assignment[]> = new BehaviorSubject([]);
  whatsappSubmissions$: BehaviorSubject<AssignmentSubmission[]> =
    new BehaviorSubject([]);
  messengerSubmissions$: BehaviorSubject<AssignmentSubmission[]> =
    new BehaviorSubject([]);
  assignmentSubmissions$: BehaviorSubject<AssignmentSubmission[]> =
    new BehaviorSubject([]);

  studentSubmissions$: BehaviorSubject<AssignmentSubmission[]> =
    new BehaviorSubject([]);

  assignmentsSubscription: Subscription;
  whatsappSubmissionSubscription: Subscription;
  messengerSubmissionSubscription: Subscription;
  assignmentSubmissionSubscription: Subscription;
  teacherCode: string;

  assignmentCollection = "tareas";
  directMessageCollection = "submission_reminders";

  whatsappSubmissionCollection = "registro_tareas";
  messengerSubmissionCollection = "registro_tareas_messenger";
  loading = true;
  couse_id: string;

  constructor(
    private auth: AngularFireAuth,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private FS: FirebaseService,
    private GS: GupshupService,
    private MS: MessengerService,
  ) {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.loading = true;

        if (this.teacherCode === undefined) {
          this.teacherCode = await this.FS.get_teacher_code();
        }
        if (+localStorage.getItem("userType") === 2) {
          await this.loadAssignment();
          await this.loadAssignmentSubmissionsOfStudent();
        } else {
          await this.loadAssignments();
          await this.loadAssignmentSubmissions();
        }
        this.loading = false;
      } else {
        this.teacherCode = undefined;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.assignmentsSubscription) {
      this.assignmentsSubscription.unsubscribe();
    }

    if (this.whatsappSubmissionSubscription) {
      this.whatsappSubmissionSubscription.unsubscribe();
    }

    if (this.messengerSubmissionSubscription) {
      this.messengerSubmissionSubscription.unsubscribe();
    }

    if (this.assignmentSubmissionSubscription) {
      this.assignmentSubmissionSubscription.unsubscribe();
    }
  }

  parseRawAssignment(id: string, assignment: RawAssignment): Assignment {
    let addRefToFilesArray = [];
    const assignmentFilesArray = assignment.assignmentFilesArray
      ? assignment.assignmentFilesArray
      : [];
    if (assignment.ref) {
      addRefToFilesArray = assignmentFilesArray.includes(assignment?.ref)
        ? assignmentFilesArray
        : [...assignmentFilesArray, assignment?.ref];
    } else {
      addRefToFilesArray = assignmentFilesArray;
    }
    return {
      id,
      file: assignment.ref,
      assignmentFilesArray: addRefToFilesArray,
      allowedFormats: assignment.allowedFormats
        ? assignment.allowedFormats
        : [],
      isDropboxOpen: assignment.isDropboxOpen ?? true,
      createdAt: assignment.fecha,
      teacherId: assignment.hash,
      courseId: assignment.materia,
      name: assignment.id,
      type: assignment.type != null ? assignment.type : "-",
      description:
        assignment.description != null ? assignment.description : undefined,
      dueDate: assignment.dueDate != null ? assignment.dueDate : undefined,
      due_Date:
        assignment.dueDate != null
          ? new Date(assignment.dueDate["seconds"] * 1000).toDateString()
          : new Date().toDateString(),
      publishDate:
        assignment.publishDate != null ? assignment.publishDate : undefined,
      publish_Date:
        assignment.publishDate != null
          ? new Date(assignment.publishDate["seconds"] * 1000).toDateString()
          : new Date().toDateString(),
      sent: assignment.sent ?? false,
      status: assignment.status,
      lastModified: assignment.lastModified?.toDate(),
      submission: [],
    };
  }

  parseRawAssignmentSubmission(
    id: string,
    submission: RawAssignmentSubmission,
  ): AssignmentSubmission {
    return {
      id,
      studentId: submission.alumno,
      grade: submission.calificacion,
      assignmentId: submission.assignmentId,
      files: submission.url,
      comments: submission.comments,
      status: submission.status,
      numberAchieve: submission.numero_archivos,
      // TODO:
    };
  }

  parseRawAssignmentSubmissionStudent(
    id: string,
    submission: RawAssignmentSubmission,
  ): AssignmentSubmission {
    return {
      id,
      studentId: submission.alumno,
      grade: submission.calificacion,
      assignmentId: submission.assignmentId,
      files: submission.url,
      comments: submission.comments,
      status: submission.status,
      numberAchieve: submission.numero_archivos,
      // TODO:
    };
  }

  async loadAssignments() {
    this.assignmentsSubscription = this.firestore
      .collection<RawAssignment>(this.assignmentCollection, (ref) =>
        ref.where("hash", "==", this.teacherCode),
      )
      .snapshotChanges()
      .pipe(
        map((snapshots) =>
          snapshots.map((snapshot) => {
            const id = snapshot.payload.doc.id;
            const assignment = snapshot.payload.doc.data() as RawAssignment;

            return { id, assignment };
          }),
        ),
      )
      .subscribe((assignments) => {
        this.assignments$.next(
          assignments
            .filter(({ assignment }) => assignment.status !== "trash")
            .map(({ id, assignment }) =>
              this.parseRawAssignment(id, assignment),
            ),
        );

        this.allAssignments$.next(
          assignments.map(({ id, assignment }) =>
            this.parseRawAssignment(id, assignment),
          ),
        );
      });
  }

  async getAssignmentByCourse(
    courseId: string,
    teacherId: string,
    studentId: string,
  ): Promise<Assignment[]> {
    try {
      const assignmentsSnapshot = await this.firestore
        .collection(this.assignmentCollection, (ref) =>
          ref.where("hash", "==", teacherId).where("materia", "==", courseId),
        )
        .get()
        .toPromise();

      const assignments = assignmentsSnapshot.docs.map((snapshot) => {
        const id = snapshot.id;
        const assignment = snapshot.data() as RawAssignment;
        return { id, assignment };
      });

      const filteredAssignments = assignments
        .filter(
          ({ assignment }) =>
            assignment.status !== "trash" && assignment.sent === true,
        )
        .map(({ id, assignment }) => this.parseRawAssignment(id, assignment));
      this.assignments$.next(filteredAssignments);
      return filteredAssignments;
    } catch (error) {
      console.error("Error fetching assignments:", error);
      throw error; // Propagate the error to the caller
    } finally {
      this.loading = false;
    }
  }

  async loadAssignmentSubmissions() {
    this.whatsappSubmissionSubscription = this.firestore
      .collection<RawAssignmentSubmission>(
        this.whatsappSubmissionCollection,
        (ref) => ref.where("profesor", "==", this.teacherCode),
      )
      .snapshotChanges()
      .pipe(
        map((snapshots) =>
          snapshots.map((snapshot) => {
            const id = snapshot.payload.doc.id;
            const submission =
              snapshot.payload.doc.data() as RawAssignmentSubmission;

            return { id, submission };
          }),
        ),
      )
      .subscribe((submissions) =>
        this.whatsappSubmissions$.next(
          submissions.map(({ id, submission }) =>
            this.parseRawAssignmentSubmission(id, submission),
          ),
        ),
      );

    this.messengerSubmissionSubscription = this.firestore
      .collection<RawAssignmentSubmission>(
        this.messengerSubmissionCollection,
        (ref) => ref.where("profesor", "==", this.teacherCode),
      )
      .snapshotChanges()
      .pipe(
        map((snapshots) =>
          snapshots.map((snapshot) => {
            const id = snapshot.payload.doc.id;
            const submission =
              snapshot.payload.doc.data() as RawAssignmentSubmission;

            return { id, submission };
          }),
        ),
      )
      .subscribe((submissions) =>
        this.messengerSubmissions$.next(
          submissions.map(({ id, submission }) =>
            this.parseRawAssignmentSubmission(id, submission),
          ),
        ),
      );

    this.assignmentSubmissionSubscription = combineLatest([
      this.whatsappSubmissions$,
      this.messengerSubmissions$,
    ]).subscribe(([whatsappSubmissions, messengerSubmissions]) => {
      this.assignmentSubmissions$.next([
        ...whatsappSubmissions,
        ...messengerSubmissions,
      ]);
    });
  }

  async getStudentSubmissions(
    courseCode: string,
    teacherCode: string,
    studentId: string,
  ): Promise<AssignmentSubmission[]> {
    const submissionsSnapshot = await this.firestore
      .collection(
        "registro_tareas",
        (ref) =>
          ref
            .where("alumno", "==", studentId)
            .where("profesor", "==", teacherCode)
            .where("id_materia", "==", courseCode),
        // .orderBy("timestamp", "desc") // Order by timestamp in descending order
        // .limit(1) // Limit the result to 1
      )
      .get()
      .toPromise();

    const submissions = submissionsSnapshot.docs.map((snapshot) => {
      const id = snapshot.id;
      const submission = snapshot.data() as RawAssignmentSubmission;
      return { id, submission };
    });

    this.whatsappSubmissions$.next(
      submissions.map(({ id, submission }) =>
        this.parseRawAssignmentSubmissionStudent(id, submission),
      ),
    );

    this.assignmentSubmissionSubscription = combineLatest([
      this.whatsappSubmissions$,
    ]).subscribe(([whatsappSubmissions]) => {
      this.assignmentSubmissions$.next([...whatsappSubmissions]);
    });

    return submissions.map(({ id, submission }) =>
      this.parseRawAssignmentSubmissionStudent(id, submission),
    );
  }

  async loadAssignmentSubmissionsOfStudent() {
    const codes_array = localStorage
      .getItem("studentCourseCodeArray")
      .split(",");
    const studentId = localStorage.getItem("userDocId");

    if (codes_array.length > 0) {
      const accumulatedSubmission: AssignmentSubmission[] = [];

      await Promise.all(
        codes_array.map(async (id) => {
          const [teacherCode, courseCode] = id.split("-");
          const submission = await this.getStudentSubmissions(
            courseCode,
            teacherCode,
            studentId,
          );
          accumulatedSubmission.push(...submission);
        }),
      );

      // Update the assignments$ observable with the accumulated assignments
      this.assignmentSubmissions$.next(accumulatedSubmission);
    }
  }

  async uploadFile(teacherCode: string, file: File) {
    const ref = this.storage.ref(`/entregas/${teacherCode}/${file.name}`);

    return (await ref.put(file)).metadata.fullPath;
  }

  async createAssignment(assignment: Omit<RawAssignment, "ref">, file: File) {
    const ref = await this.uploadFile(await this.FS.get_teacher_code(), file);

    return this.firestore.collection(this.assignmentCollection).add({
      ...assignment,
      ref,
    });
  }

  async updateAssignment(
    id: string,
    assignment: Partial<RawAssignment>,
    file?: File,
  ) {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      const updatedAssignment: Partial<RawAssignment> = assignment;

      if (file) {
        updatedAssignment.ref = await this.uploadFile(
          await this.FS.get_teacher_code(),
          file,
        );
      }

      await this.firestore
        .collection(this.assignmentCollection)
        .doc(id)
        .update(updatedAssignment);
    }
  }

  async updateAssignmentFiles(
    id: string,
    assignment: Partial<RawAssignment>,
    file?: File,
  ) {
    if (!assignment.assignmentFilesArray) {
      assignment.assignmentFilesArray = [];
    }

    if (await this.auth.authState.pipe(first()).toPromise()) {
      if (file) {
        const ref = await this.uploadFile(
          await this.FS.get_teacher_code(),
          file,
        );
        if (!assignment.assignmentFilesArray.includes(ref)) {
          assignment.assignmentFilesArray.push(ref);
        }
      }
      await this.firestore
        .collection(this.assignmentCollection)
        .doc(id)
        .update(assignment);
    }
  }

  async deleteAssignment(
    { name, courseId }: Assignment,
    when: "now" | "30-days" = "30-days",
  ) {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      const teacherCode = await this.FS.get_teacher_code();

      const rawAssignment = await this.firestore
        .collection(this.assignmentCollection, (ref) =>
          ref
            .where("hash", "==", teacherCode)
            .where("id", "==", name)
            .where("materia", "==", courseId),
        )
        .get()
        .toPromise();

      const assignment = this.firestore
        .collection(this.assignmentCollection)
        .doc(rawAssignment.docs[0].id);

      if (when === "now") {
        await assignment.delete();
      } else {
        await assignment.update({ status: "trash" });
      }
    }
  }

  async restoreAssignment(id: string) {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      const assignment = this.firestore
        .collection(this.assignmentCollection)
        .doc(id);

      await assignment.update({ status: null });
    }
  }

  async saveDirectMessage(directMsg: DirectMesage) {
    await this.firestore
      .collection<DirectMesage>(this.directMessageCollection)
      .doc()
      .set(directMsg);
  }

  async sendAssignmentComment(
    submission: AssignmentSubmission,
    comment: AssignmentSubmissionComment,
  ) {
    await this.firestore
      .collection<RawAssignmentSubmission>(this.whatsappSubmissionCollection)
      .doc(submission.id)
      .update({
        comments: [comment, ...(submission.comments ?? [])],
      });
  }

  async commentByStudent(
    submission: AssignmentSubmission,
    comment: StudentCommentOnSubmission,
  ) {
    await this.firestore
      .collection<RawAssignmentSubmission>(this.whatsappSubmissionCollection)
      .doc(submission.id)
      .update({
        comments: [comment, ...(submission.comments ?? [])],
      });
  }

  async updateAssignmentSubmissionGrade(
    student: Student,
    submission: AssignmentSubmission,
    course_id: string,
    grade: AssignmentSubmission["grade"],
  ) {
    await this.firestore
      .collection<RawAssignmentSubmission>(this.whatsappSubmissionCollection)
      .doc(submission.id)
      .update({
        calificacion: grade,
      });

    await this.GS.sendGradeMessage(grade, course_id, [
      {
        type: "whatsapp",
        student,
      },
    ]);
    await this.MS.sendGradeMessage(grade, course_id, [
      {
        type: "facebook",
        student,
      },
    ]);
  }

  async loadAssignment() {
    const codes_array = localStorage
      .getItem("studentCourseCodeArray")
      .split(",");
    const studentId = localStorage.getItem("userDocId");
    if (codes_array.length > 0) {
      const accumulatedAssignments: Assignment[] = [];

      await Promise.all(
        codes_array.map(async (id) => {
          const [teacherCode, courseCode] = id.split("-");
          const assignments = await this.getAssignmentByCourse(
            courseCode,
            teacherCode,
            studentId,
          );
          accumulatedAssignments.push(...assignments);
        }),
      );

      // Update the assignments$ observable with the accumulated assignments
      this.assignments$.next(accumulatedAssignments);
    }
  }

  async unpublishAssignmentStatus(id: string) {
    if (await this.auth.authState.pipe(first()).toPromise()) {
      const assignment = this.firestore
        .collection(this.assignmentCollection)
        .doc(id);
      await assignment.update({ sent: false });
    }
  }
}
