import { Component, EventEmitter, Input, Output, OnChanges } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TRANSLATION } from "@i18n";
import { HotToastService } from "@ngneat/hot-toast";
import { TranslateService } from "@ngx-translate/core";
import { count } from "console";
import "firebase/auth";
import "firebase/firestore";
import { CourseService } from "src/app/services/course.service";
import { FirebaseService } from "src/app/services/firebase.service";
import { GupshupService } from "src/app/services/gupshup.service";
import { InstitutionLocation } from "src/app/shared/types";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnChanges {
  @Input() signupType:
    | "educator"
    | "learner"
    | "institution"
    | "institution2"
    | "institution3"
    | "institution4"
    | undefined;
  @Output() openSigninModalAction = new EventEmitter<string>();
  @Output() openSignupModalAction = new EventEmitter<string>();

  loading = false;
  refreshingCourses = false;
  showPassword: boolean = false;
  form: FormGroup;
  availableCourses: any[];

  lastTeacherCode: string;
  emailErr: string;
  passwordErr: string;
  phoneErr: string;
  nameErr: string;
  teacherCodeErr: string;
  courseErr: string;
  confirmPasswordErr: string;
  isMessenger = false;
  agreeWithTerms = false;
  totalErr: string;
  yearErr: string;
  cityErr: string;
  countryErr: string;

  //institutionLocations: Array<InstitutionLocation>
  totalLoc: { country: any; city: any; nickname : string; state : string; address: string; }[] = [];
  PROFILE_FILE: File;

  email_exists : boolean = false;

  constructor(
    private toast: HotToastService,
    private firestore: AngularFirestore,
    private GS: GupshupService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private FS: FirebaseService,
    private route: ActivatedRoute,
    private courseService: CourseService,
  ) {
    let {
      firstName,
      lastName,
      number,
      messenger_id,
    }: {
      firstName?: string;
      lastName?: string;
      number?: string;
      messenger_id?: string;
    } = this.route.snapshot.queryParams;

    if (firstName || lastName || number || messenger_id) {
      this.selectSignupType("learner", {
        firstName,
        lastName,
        number,
        messengerId: messenger_id,
      });
    }
  }
  ngOnChanges() {
    this.signupType=undefined;
  }
  isValidEmail(e: string) {
    return new RegExp(
      /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/,
    ).test(e);
  }

  isValidPhone(e: string) {
    return new RegExp(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
    ).test(e);
  }

  selectSignupType(
    type:
      | "educator"
      | "learner"
      | "institution"
      | "institution2"
      | "institution3"
      | "institution4",
    options?: {
      firstName?: string;
      lastName?: string;
      number?: string;
      messengerId?: string;
      institution_name?: string;
      institution_type?: string;
      institution_establish_date?: Date;
      institution_about?: string;
      institution_country?: string;
      institution_city?: string;
      //institution_location?: InstitutionLocation;
      logo_reference?: firebase.default.storage.Reference;
      fully_remote?: boolean;
    },
  ) {
    this.signupType = type;

    if (type === "educator") {
      this.form = this.formBuilder.group({
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required]],
        confirmPassword: ["", [Validators.required]],
        firstName: [options?.firstName ?? "", [Validators.required]],
        lastName: [options?.lastName ?? "", [Validators.required]],
        phone: [
          options?.number ?? options?.messengerId ?? "",
          [Validators.required],
        ],
      });
    } else if (type === "learner") {
      this.form = this.formBuilder.group({
        phone: [
          options?.number ?? options?.messengerId ?? "",
          [Validators.required],
        ],

        teacherCode: ["", [Validators.required]],
        course: [null, [Validators.required]],
      });

      if (options?.number || options?.messengerId) {
        this.form.controls.phone.disable();

        if (options?.messengerId) {
          this.isMessenger = true;
        }
      }

      if (options?.firstName) {
        this.form.controls.firstName.disable();
      }
      if (options?.lastName) {
        this.form.controls.lastName.disable();
      }
    } else if (type === "institution") {
      this.form = this.formBuilder.group({
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required]],
        confirmPassword: ["", [Validators.required]],
        firstName: [options?.firstName ?? "", [Validators.required]],
        lastName: [options?.lastName ?? "", [Validators.required]],
        institutionName: [
          options?.institution_name ?? "",
          [Validators.nullValidator],
        ],
        institutionType: [
          options?.institution_type ?? "",
          [Validators.nullValidator],
        ],
        institutionEstablishDate: [
          options?.institution_establish_date ?? new Date(),
          [Validators.nullValidator],
        ],
        institutionAbout: [
          options?.institution_about ?? "",
          [Validators.nullValidator],
        ],
        institutionLogoReference: [
          options?.logo_reference ?? "",
          [Validators.nullValidator],
        ],
        fullyRemote: [
          options?.fully_remote ?? false,
          [Validators.nullValidator],
        ],
        institutionCountry: [
          options?.institution_country ?? "",
          Validators.nullValidator,
        ],
        institutionCity: [
          options?.institution_city ?? "",
          Validators.nullValidator,
        ],
        //institutionLocation : [options?.institution_location ?? new FormControl<InstitutionLocation>({
        //  country: "", city: ""}), Validators.nullValidator],
        //institutionLocations : [options?.institutionLocations ?? new Array<InstitutionLocation>(), [Validators.nullValidator]],
      });
    } else if (type === "institution2") {
      console.log("institution2", this.form.value);
    } else if (type === "institution4") {
      console.log("institution4", this.form.value);
      //this.institutionLocations = new Array<InstitutionLocation>();
    }
  }

  passwordToggle() {
    this.showPassword = !this.showPassword;
  }

  async handleChange() {
    this.emailErr = "";
    this.passwordErr = "";
    this.confirmPasswordErr = "";
    this.phoneErr = "";
    this.nameErr = "";
    this.teacherCodeErr = "";
    this.courseErr = "";

    if (this.signupType === "educator") {
      const email = this.form.controls.email.value;
      const password = this.form.controls.password.value;
      const firstName = this.form.controls.firstName.value;
      const lastName = this.form.controls.lastName.value;
      const confirmPassword = this.form.controls.confirmPassword.value;
      const phone = this.form.controls.phone.value;

      if (email.length < 1) {
        this.emailErr = "Email is required";
      } else if (!this.isValidEmail(email)) {
        this.emailErr = "Email is not valid";
      }
      if (phone.length < 1) {
        this.phoneErr = "Phone number is requerid";
      } else if (!this.isValidPhone(phone)) {
        this.phoneErr = "Phone number is not valid";
      }
      if (password.length < 1) {
        this.passwordErr = "Password is required";
      }
      if (password !== confirmPassword) {
        this.confirmPasswordErr = "Password needs to be confirmed";
      }

      if (firstName.length < 1) {
        this.nameErr = "Name is required";
      }
      if (lastName.length < 1) {
        this.nameErr = "Name is required";
      }
    }

    if (this.signupType === "learner") {
      const phone = this.form.controls.phone.value;
      const name1 = this.form.controls.firstName.value;
      const name2 = this.form.controls.lastName.value;
      const teacherCode = this.form.controls.teacherCode.value;
      const course = this.form.controls.course.value;

      if (phone.length < 1) {
        this.phoneErr = "Phone Number is required";
      }

      if (name1.length < 1) {
        this.nameErr = "Name is required";
      }

      if (name2.length < 1) {
        this.nameErr = "Name is required";
      }

      if (teacherCode.length < 1) {
        this.teacherCodeErr = "Teacher Code is required";
      } else if (this.lastTeacherCode !== teacherCode) {
        this.refreshingCourses = true;

        try {
          this.availableCourses =
            await this.courseService.getCourses(teacherCode);
        } catch (err) {
          this.teacherCodeErr = `There is no teacher with code "${teacherCode}"`;
          console.error(`Failed to load courses for teacher ${teacherCode}`);
        }

        this.form.controls.course.setValue(null);
        this.refreshingCourses = false;
      }

      if (course == null || this.lastTeacherCode !== teacherCode) {
        this.courseErr = "Course is required";
      }

      this.lastTeacherCode = teacherCode;
    }

    if (this.signupType === "institution") {
      const name1 = this.form.controls.firstName.value;
      const name2 = this.form.controls.lastName.value;
      const email = this.form.controls.email.value;

      this.email_exists = await this.FS.check_registered_email(email)

      if(this.email_exists){
        this.emailErr = "Email is previously registered. Please, use other email"
      }

      const password = this.form.controls.password.value;
      const confirmPassword = this.form.controls.confirmPassword.value;
      const institution_name = this.form.controls.institutionName.value;
      const institution_type = this.form.controls.institutionType.value;
      const city = this.form.controls.institutionCity.value;
      const country = this.form.controls.institutionCountry.value;

      if (email.length < 1) {
        this.emailErr = "Email is required";
      } else if (!this.isValidEmail(email)) {
        this.emailErr = "Email is not valid";
      }
      if (password.length < 1) {
        this.passwordErr = "Password is required";
      }
      if (password !== confirmPassword) {
        this.confirmPasswordErr = "Password needs to be confirmed";
      }

      if (name1.length < 1) {
        this.nameErr = "Name is required";
      }
      if (name2.length < 1) {
        this.nameErr = "Name is required";
      }
    }

    if (this.signupType === "institution3") {
      const yearSelector = this.form.controls.institutionEstablishDate.value;

      if (yearSelector > this.currentYear) {
        this.yearErr = "You can't use future year";
      } else if (yearSelector < 1800) {
        this.yearErr = "Year can't be under 1800";
      } else {
        this.yearErr = "";
      }
    }
  }

  async registerUser() {
    this.loading = true;

    if (this.signupType === "educator" && this.form.valid) {
      try {
        const email = this.form.controls.email.value;
        const password = this.form.controls.password.value;
        const confirmPassword = this.form.controls.confirmPassword.value;
        const firstname = this.form.controls.firstName.value;
        const lastname = this.form.controls.lastName.value;
        const phone = this.form.controls.phone.value;
        if (password === confirmPassword) {
          await this.FS.registerUser(password, {
            firstname,
            lastname,
            email,
            phone,
          });
        }
      } catch (err) {
        this.totalErr = err;
      }
    } else if (this.signupType === "learner" && this.form.valid) {
      let message = this.translate.instant(TRANSLATION.CONFIRM_ON_WHATSAPP);
      const name = this.form.controls.name.value;
      const phone = this.form.controls.phone.value;
      const teacherCode = this.form.controls.teacherCode.value;
      const course = this.form.controls.course.value;

      const user: any = {
        codigo: `${teacherCode}-${course.id.trim()}`,
        nombre: name,
        phone,
        verificado: false,
      };

      if (this.isMessenger) {
        /*
        try {
          user.messenger_id = atob(phone);
        } catch (err) {
          user.messenger_id = phone;
        }
        */
        user.messenger_id = phone;
        user.verificado = true;

        message = this.translate.instant(TRANSLATION.REGISTERATION_RECEIEVED);
      }

      await this.firestore.collection("alumnos").doc().set(user);

      this.toast.info(message);

      if (!this.isMessenger) {
        await this.GS.sendMessage(
          phone.trim(),
          this.translate.instant(TRANSLATION.ACTION_CONFIRM) + name,
        ).toPromise();
      }
    } else if (this.form.valid) {
      const email = this.form.controls.email.value;
      const password = this.form.controls.password.value;
      const confirmPassword = this.form.controls.confirmPassword.value;

      const firstname = this.form.controls.firstName.value;
      const lastname = this.form.controls.lastName.value;

      //const phone = this.form.controls.phone.value;
      //console.log(phone)
      const institutionName = this.form.controls.institutionName.value;
      const institutionType = this.form.controls.institutionType.value;
      const institutionEstablishDate =
        this.form.controls.institutionEstablishDate.value;
      const fullyRemote = this.form.controls.fullyRemote.value;
      const institutionCountry = this.form.controls.institutionCountry.value;
      const institutionCity = this.form.controls.institutionCity.value;
      const institutionAbout = this.form.controls.institutionAbout.value;

      if (password === confirmPassword) {
        let profile_ref = undefined;

        let institutionID = await this.FS.registerInstitution({
          institutionName,
          institutionType,
          institutionEstablishDate,
          institutionAbout,
          institutionVerified: false,
          fullyRemote,
          institutionLocations: this.totalLoc,
          institutionRecognitions : [],
          institutionSocialAccounts : [],          
        });

        if (this.PROFILE_FILE) {
          profile_ref = await this.FS.updateInstitutionProfileImage(
            this.PROFILE_FILE,
            institutionID,
          );
        }

        await this.FS.registerUser(password, {
          firstname,
          lastname,
          email,
          //phone,
          isAdmin: true,
          institution_id: institutionID,
        });
      }
    }

    this.loading = false;
  }

  openSigninodalAction() {
    this.openSigninModalAction.emit();
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  checkLocation() {
    if (
      this.form.controls.institutionCountry.value.length === 0 &&
      !this.form.controls.fullyRemote
    ) {
      return this.countryErr;
    }
    if (
      this.form.controls.institutionCity.value.lenghth === 0 &&
      !this.form.controls.fullyRemote
    ) {
      return this.cityErr;
    }
    this.totalLoc.push({
      country: this.form.controls.institutionCountry.value,
      city: this.form.controls.institutionCity.value,
      nickname: "",
      state: '',
      address: ''
    });
  }

  updateFile(event) {
    const fileInput = event.target as HTMLInputElement;
    this.PROFILE_FILE = fileInput.files[0];
  }
}
